import { Avatar, Tooltip } from 'antd';
import { ProjectMember } from '../../entities';

type Props = {
    members: ProjectMember[];
};

const ProjectMembersAvatars = ({ members = [] }: Props) => (
    <Avatar.Group maxCount={5}>
        {members.map(m => (
            <Tooltip title={`${m.user.name} - ${m.role}`} placement="top" key={m.id}>
                <Avatar src={m.user.picture}>{m?.user?.name ? m.user.name.charAt(0) : ''}</Avatar>
            </Tooltip>
        ))}
    </Avatar.Group>
);

export default ProjectMembersAvatars;
