import React from 'react';
import axios from 'axios';
import { parse } from 'query-string';
import Chat from './pages/dashboard/dashboard';
import ChannelChat from './pages/chat/channel-chat';
import { URL_PARAMS, VIEW_TYPES } from './constants';
import './index.scss';
import { Result } from './dal';

interface IProps {
  data: string;
  chatData?: Result;
}

const axiosChatInstance = axios.create({
  baseURL: process.env.REACT_APP_CHAT_API_BASE_URL,
});

const ChatApp: React.FC<IProps> = ({ data, chatData }) => {
  // const { createNotification, contextHolder } = useNotify();
  const searchParams = parse(data) as { [key: string]: string | null },
    chatType = searchParams[URL_PARAMS.VIEW];

  React.useEffect(() => {
    axiosChatInstance.interceptors.response.use(
      response => response,
      error => {
        const message = error.response?.data?.message;
        const status = error.response?.status;
        if (message && status !== 500) {
          // createNotification(message, 'Error: ' + status, 'error');
        }
        throw error;
      },
    );
  }, []);

  return (
    <div id="chat-container">
      {chatType === VIEW_TYPES.DIRECT ? <Chat data={data} /> : <ChannelChat idToCheck={searchParams} data={data} upChatData={chatData} />}
    </div>
  );
};

export { axiosChatInstance };

export default ChatApp;
