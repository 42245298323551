import { useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './Login.css';
import logo from '../images/quartertonez-logo.png';
import { User } from '../entities';
import { useSession } from '../dal';
import { Divider, message } from 'antd';
import EmailPasswordForm from '../components/login/EmailPasswordForm';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { isBrowser } from 'react-device-detect';
import EnvMessage from '../components/EnvMessage';
import VersionMessage from '../components/VersionMessage';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate } = useSession();

  const { from }: any = location.state || { from: { pathname: '/' } };


  const onSuccess = useCallback(
    async (googleData: any) => {
      await axios.post<{ data: User }>('/google-login', { token: googleData.credential });
      await mutate();
      navigate(from.pathname);
    },
    [navigate, from.pathname, mutate],
  );

  const onEmailPasswordSubmit = async (values: { email: string; password: string }) => {
    try {
      await axios.post<{ data: User }>('/login', { ...values });
      await mutate();
      navigate(from.pathname);
    } catch (e) {
      if ((e as AxiosError).response?.status === 409) {
        message.error('Email not found');
      }

      if ((e as AxiosError).response?.status === 401) {
        message.error('Invalid password');
      }
    }
  };

  return (
    <div className="main-container">
      {isBrowser && <div className="left-container">
        <div style={{textAlign: "center"}}>
          <img src={logo} alt="Logo"  />
          <br />
          <br />
          <EnvMessage />
          <br />
          <VersionMessage />
        </div>
      </div>}
      <div className="right-container">
        <div>
          <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
            <GoogleLogin
              onSuccess={onSuccess}
              onError={() => console.log('Login failed')}
            />
          </GoogleOAuthProvider>
          <Divider>or</Divider>
          <EmailPasswordForm onFinish={onEmailPasswordSubmit} />
        </div>
      </div>
    </div>
  );
};

export default Login;
