import { Table, Button, Drawer, Space, InputNumber } from 'antd';
import { ClockCircleOutlined, MessageOutlined } from '@ant-design/icons';
import AddTaskForm from './AddTaskForm';
import { nullableDataSorter } from '../../common/utils';
import { Project, Task, User } from '../../entities';
import TaskDetails from './TaskDetails';
import TaskName from './TaskName';
import TaskAssignees from './TaskAssignees';
import TaskDueDate from './TaskDueDate';
import TaskProject from './TaskProject';
import './TaskTable.css';
import { SortOrder } from 'antd/lib/table/interface';
import { useState } from 'react';
import TaskStateTag from './TaskStateTag';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { TasksPermissions } from '../../common/usePermissions/permissions';
import { useSession } from '../../dal';
import TimesheetFormRow from '../Timesheet/TimesheetFormRow';
// import { CalendarOutlined, StarOutlined } from '@ant-design/icons';
// import moment from 'moment';

// const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
//   <Space>
//     {React.createElement(icon)}
//     {text}
//   </Space>
// );

type Props = {
  data: Task[];
  initialProjectId?: string;
  initialPhaseId?: string;
  initialTicketId?: string;
  personal: boolean;
  userId?: number;
  onTaskChange: (data: Partial<Task>) => Promise<void>;
};

const TasksTable = ({ data, initialProjectId, initialPhaseId, initialTicketId, personal, userId, onTaskChange }: Props) => {
  const { hasPermission } = usePermissions();
  const { session } = useSession();
  const [detailedTask, setDetailedTask] = useState<Task | undefined>();
  const [timeTrackingTask, setTimeTrackingTask] = useState<Task | undefined>();
  const showDetails = (taskId: string) => {
    const task = data.filter(t => t.id === taskId)[0];
    setDetailedTask(task);
  };

  const showTracking = (taskId: string) => {
    const task = data.filter(t => t.id === taskId)[0];
    setTimeTrackingTask(task);
  };

  const closeDetails = () => {
    setDetailedTask(undefined);
  };

  const closeTracking = () => {
    setTimeTrackingTask(undefined);
  };

  const isTaskAssignedToUser = (assignees: User[] | null): boolean => {
    return assignees ? assignees.filter(a => a.id === session?.id).length > 0 : false;
  };

  const columns = [
    {
      dataIndex: 'name',
      title: 'Task',
      sorter: (a: Task, b: Task) => nullableDataSorter(a.name, b.name),
      render: (value: string, record: Task) => <Space style={{ width: '100%' }} direction="vertical">
        <TaskName task={record} onTaskChange={onTaskChange} />
        <div style={{ textAlign: 'right' }}><TaskStateTag task={record} onTaskChange={onTaskChange} /></div>
      </Space>
    },
    {
      dataIndex: 'dueDate',
      title: 'Due date',
      defaultSortOrder: 'ascend' as SortOrder,
      width: '135px',
      render: (value: any, record: Task) => <TaskDueDate task={record} onTaskChange={onTaskChange} />,
      sorter: (a: Task, b: Task) => nullableDataSorter(a.dueDate, b.dueDate),
    },
    {
      dataIndex: 'project',
      title: 'Project',
      render: (value: any, record: Task) =>
        !personal ? (
          <a href={`/projects/${record.project?.id}?defaultTab=tasks`}>
            {record.project?.client?.name} - {record.project?.name}
          </a>
        ) : (
          <TaskProject task={record} onTaskChange={onTaskChange} />
        ),
      sorter: (a: Task, b: Task) => nullableDataSorter(a.project?.name, b.project?.name),
    },
    {
      dataIndex: 'assignees',
      title: 'Assignees',
      width: '150px',
      render: (value: any, record: Task) => <TaskAssignees task={record} onTaskChange={onTaskChange} />,
      sorter: (a: Task, b: Task) => nullableDataSorter(a.dueDate, b.dueDate),
    },
    {
      dataIndex: 'estimate',
      title: 'Estimate [h]',
      width: '100px',
      render: (value: any, record: Task) => <InputNumber defaultValue={value} onChange={e => onTaskChange({ id: record.id, estimate: e })} min={0} precision={1} style={{ width: '75px' }} />
    },
    {
      dataIndex: 'details',
      title: '',
      width: '100px',
      render: (value: any, record: Task) => (
        <Space direction="vertical">
          <Button type="link" onClick={() => showDetails(record.id)}>
            More
            {record.description && <MessageOutlined />}
          </Button>
          {isTaskAssignedToUser(record.assignees) && (
            <Button type="text" onClick={() => showTracking(record.id)}>
              <ClockCircleOutlined />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const visibleColumns = columns
    .filter((c: any) => !initialProjectId || c.dataIndex !== 'project')
    .filter((c: any) => !personal || c.dataIndex !== 'assignees');

  return (
    <>
      <Table
        dataSource={data}
        columns={visibleColumns}
        size="small"
        className="task-table"
        pagination={false}
        rowKey={record => record.id}
        summary={() =>
          hasPermission(TasksPermissions.TASKS_WRITE) && (
            <AddTaskForm projectId={initialProjectId} phaseId={initialPhaseId} ticketId={initialTicketId} personal={personal} userId={userId} />
          )
        }
      />
      {/* <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 3,
        }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            actions={[
              <TaskStateTag task={item} onTaskChange={onTaskChange} key="task-state" />,
              <IconText icon={CalendarOutlined} text={`${item.dueDate ? moment(item.dueDate).format('ll') : '?'}`} key="list-vertical-like-o" />,
              <IconText icon={ClockCircleOutlined} text={`${item.estimate}h`} key="list-vertical-message" />,
              <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
              <TaskAssignees task={item} onTaskChange={onTaskChange} key="task-assignees" />
            ]}
            extra={
              <Space>
                <Button type="default" style={{ color: item.description ? 'green' : 'black' }} onClick={() => showDetails(item.id)}>
                  <MoreOutlined />
                </Button>
                {isTaskAssignedToUser(item.assignees) && (
                  <Button type="primary" onClick={() => showTracking(item.id)}>
                    <ClockCircleOutlined />
                  </Button>
                )}
              </Space>
            }
          >
            <List.Item.Meta
              title={<TaskName task={item} onTaskChange={onTaskChange} />}
              description={item.description}
            />
          </List.Item>
        )}
      /> */}
      {detailedTask && (
        <Drawer title={detailedTask.name} placement="right" size="large" onClose={closeDetails} open={!!detailedTask}>
          <TaskDetails task={detailedTask} onTaskChange={onTaskChange} />
        </Drawer>
      )}
      {timeTrackingTask && (
        <Drawer title={timeTrackingTask.name} placement="right" size="large" onClose={closeTracking} open={!!timeTrackingTask}>
          <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <TimesheetFormRow
              projectId={(timeTrackingTask.project as Project).id}
              taskId={timeTrackingTask.id}
              user={session}
              date={new Date().toString()}
              vertical
              onSubmitAction={closeTracking}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};

export default TasksTable;
