import React from 'react';
import moment from 'moment';

import { Comment, Tooltip, Empty } from 'antd';

import { User } from '../../entities';
import { ChatChannel, ChatMessage, ChatMessageReplyProps, StateCB } from '../../entities/chat';
import Reaction from './Reaction';

import InfiniteScroll from 'react-infinite-scroll-component';

import './Messages.css';
import Reactions from './Reactions';
import {
  AMOUNT_OF_COMMENTS_TO_LOAD,
  AMOUNT_OF_MESSAGES_TO_LOAD,
  CHAT_ACTION_MESSAGE,
  INITIAL_AMOUNT_OF_LOADED_COMMENTS,
  INITIAL_AMOUNT_OF_LOADED_MESSAGES,
  S3_URL_MASK,
  MESSAGE_ACTIONS_DATA,
} from '../../constants';

import Xarrow from 'react-xarrows';
import { FileOutlined } from '@ant-design/icons';

import DonwloadIcon from '../../static/images/download_icon.svg';
import { formatBytes } from '../../common/utils';
import ImageModal from './imageModal';
import { axiosChatInstance } from '../../chat_main';

interface MessagesProps {
  user: User;
  chatChannel: ChatChannel;
  messages: ChatMessage[];
  setReplyTo: (message?: ChatMessageReplyProps | StateCB) => void;
  loadMessages: (part: number) => void;
  controlFunctions: any;
  isAllowToCommunicate: string | null;
  chatWhereUserMember: ChatChannel[];
  replyTo?: ChatMessageReplyProps;
  style?: any;
  users?: User[];
}

interface MessageListProps {
  messages: ChatMessage[];
  user: User;
  setReplyTo: (message?: ChatMessageReplyProps | StateCB) => void;
  isAllowToCommunicate: string | null;
  handleReadMessage: (message: ChatMessage, user: User, manual?: boolean) => void;
  setUnread: (message: ChatMessage, user: User) => void;
  loadMessages: (part: number) => void;
  replyTo?: ChatMessageReplyProps;
  isItReply?: boolean;
  users?: User[];
}

const MessageList = ({
  messages,
  user,
  setReplyTo,
  replyTo,
  handleReadMessage,
  setUnread,
  isAllowToCommunicate,
  loadMessages,
  isItReply,
  users,
}: MessageListProps) => {
  const [isOver, setIsOver] = React.useState<string | null>();
  const [loadedTimes, setLoadedTimes] = React.useState(INITIAL_AMOUNT_OF_LOADED_MESSAGES);
  const [threadLoaded, setThreadLoaded] = React.useState(INITIAL_AMOUNT_OF_LOADED_COMMENTS);

  const openEmojiModal = (id: string) => {
    setTimeout(() => {
      setIsOver(id);
    }, 0);
  };

  React.useEffect(() => {
    if (isItReply && messages.length) {
      if (messages[0].shouldDisplay) {
        setThreadLoaded(pV => pV + 1);
      }
    }
  }, [messages, isItReply]);

  const closeEmojiModal = (e: any) => {
    if (e.target.className.includes && !e.target.className.includes('epr')) {
      setIsOver(null);
    }
  };

  const getMessageToDisplay = (): ChatMessage[] => {
    if (isItReply) {
      const [fIndex, lIndex] = [0, threadLoaded - 1];
      const result = messages.filter((message, index) => message.shouldDisplay || (index >= fIndex && index <= lIndex));

      if (result.length > threadLoaded) {
        setThreadLoaded(result.length);
      }
      return result;
    } else return messages;
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', closeEmojiModal, true);

    return () => {
      document.removeEventListener('mousedown', closeEmojiModal, true);
    };
  }, []);

  const loadMoreComments = () => {
    setThreadLoaded(pV => pV + AMOUNT_OF_COMMENTS_TO_LOAD);
    setIsLoaded(false);
  };

  const loadMoreData = () => {
    const newValue = loadedTimes + AMOUNT_OF_MESSAGES_TO_LOAD;
    setLoadedTimes(newValue);
    loadMessages(newValue);
  };

  React.useEffect(() => {
    if (user) {
      if (!document.body.getElementsByClassName(`style-mention-${user.id}`).length) {
        const el = document.createElement('style');
        el.className = `style-mention-${user.id}`;
        el.innerHTML = `.mention-${user.id} {background: #f2c74466}`;
        document.body.appendChild(el);
      }
    }
  }, [user]);

  React.useEffect(() => {
    if (isItReply && messages && !messages[0].author['scrolledTo'] && !messages[0].author.updatedAt) {
      const message = messages[0];
      if (message.author.id === user.id) {
        const elem = document.getElementById(message.replyTo.id);
        if (elem) {
          messages[0].author['scrolledTo'] = true;
          setTimeout(() => elem.scrollIntoView({ behavior: 'smooth', block: 'end' }), 1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  function srollToElem(el: HTMLElement | null) {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

  const handleMessageAction = async (key: string, data: any, messageId: string) => {
    axiosChatInstance({
      url: `${process.env.REACT_APP_CHAT_API_BASE_URL}chat/message/action`,
      method: 'POST',
      data: {
        key,
        data,
        messageId,
      },
    });
  };

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [showImage, setShowImage] = React.useState<string | null>();

  return (
    messages && (
      <>
        <InfiniteScroll
          dataLength={getMessageToDisplay().length}
          className={isItReply ? '!overflow-visible' : ''}
          next={loadMoreData}
          hasMore={isItReply ? false : true}
          inverse={true}
          style={{ display: 'flex', flexDirection: 'column-reverse', height: '100%', overflow: 'visible' }}
          loader={isItReply ? '' : <div className="w-full text-center text-base">This is the beginning of a chat room 🤐</div>}
          scrollableTarget="scrolableDiv"
        >
          {getMessageToDisplay().map((item: ChatMessage) => {
            const manualUnread = item.unReadBy.find(u => u.id === user.id);
            const read = !manualUnread && item.readBy.find(u => u.id === user.id);
            return (
              <div
                key={item.id + '-message'}
                id={item.id}
                className={
                  'comment mb-[5px] mx-3 ' +
                  `${item.isServiceMessage && '!text-[#616061]'} ${isItReply && 'reply-comment '}` +
                  (read ? '' : !item.isServiceMessage && ' unread ') +
                  ` ${replyTo && replyTo.id === item.id && 'to-reply !bg-[#fff9dd] rounded-[15px]'} ${isItReply && !read && ' !mr-0'}`
                }
                onMouseEnter={e => {
                  if (!item.isServiceMessage && !read && !manualUnread) handleReadMessage(item, user);
                  e.stopPropagation();
                }}
                onLoad={() => {
                  if (item.replyTo) {
                    setIsLoaded(true);
                  }
                }}
              >
                {isLoaded &&
                  document.getElementById(item?.replyTo?.id)?.getElementsByClassName('ant-comment-avatar')[0] &&
                  document.getElementById(item.id)?.getElementsByClassName('ant-comment-avatar')[0] && (
                    <Xarrow
                      color="#D9DCE3"
                      path="grid"
                      startAnchor="bottom"
                      endAnchor="left"
                      start={{ current: document.getElementById(item?.replyTo?.id)?.getElementsByClassName('ant-comment-avatar')[0] }}
                      end={{ current: document.getElementById(item.id)?.getElementsByClassName('ant-comment-avatar')[0] }}
                      strokeWidth={1}
                      showHead={false}
                    />
                  )}
                <Comment
                  actions={[
                    isAllowToCommunicate !== undefined && !isItReply && !item.isServiceMessage && (
                      <span
                        className="!m-0 !text-sm !mr-3"
                        onClick={() => {
                          setReplyTo(() => {
                            setTimeout(() => srollToElem(document.getElementById(item.id)), 0);
                            return item.replyTo ? { id: item.replyTo.id, text: item.text } : item;
                          });
                        }}
                      >
                        Reply
                      </span>
                    ),
                    isAllowToCommunicate !== undefined && !item.isServiceMessage && (
                      <div
                        onClick={() => openEmojiModal(item.id)}
                        className="add-reactions cursor-pointer transition-all hover:bg-[#4F67FF] rounded-[62px] mr-3 bg-[#E9EBEF] w-8 h-[22px] flex justify-center items-center"
                      >
                        <div className="add-reactions-logo "></div>
                      </div>
                    ),
                    <Reactions isAllowToCommunicate={isAllowToCommunicate} user={user} reactions={item.reactions} message={item} />,
                    read && !item.isServiceMessage && (
                      <span className="!text-sm" onClick={() => setUnread(item, user)}>
                        Mark as unread
                      </span>
                    ),
                    manualUnread && !item.isServiceMessage && (
                      <span className="!text-sm" onClick={() => handleReadMessage(item, user, true)}>
                        Mark as read
                      </span>
                    ),
                  ]}
                  author={item.author?.name}
                  avatar={item.author?.picture}
                  content={
                    <>
                      <div dangerouslySetInnerHTML={{ __html: item.text }} className={item.isServiceMessage ? '!text-[#616061]' : ''}></div>
                      <div className="flex flex-wrap">
                        {item?.files?.map(file => {
                          const [name, type, filterKey, fileSize] = file.split('&');
                          const fileURL = `${S3_URL_MASK}${item.author.id}/${filterKey}/${name}`;

                          return type.split('/')[0] === 'image' ? (
                            <div
                              key={fileURL}
                              onClick={() => {
                                setShowImage(fileURL);
                              }}
                              className="message-file-image"
                            >
                              <img src={fileURL} alt={name} />
                            </div>
                          ) : (
                            <div className="message-file" key={fileURL}>
                              <FileOutlined className=" text-[32px]" />
                              <div className="message-file-data ml-2">
                                <div className="file-name">{name}</div>
                                <div className="file-size">{fileSize ? formatBytes(Number(fileSize), 0) : '??? mb'}</div>
                              </div>
                              <div className="file-actions flex ml-auto">
                                <a href={fileURL} target="_blank" className="download-file" rel="noreferrer">
                                  <img src={DonwloadIcon} alt="donwload-icon" />
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="message-actions flex">
                        {item.actions?.map(action => {
                          const [key, data] = action.split('@');
                          return (
                            <div key={key} className="message-action" onClick={() => handleMessageAction(key, data, item.id)}>
                              {MESSAGE_ACTIONS_DATA[key]?.title}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  }
                  datetime={
                    <Tooltip title={moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')}>
                      <span>{moment(item.createdAt).fromNow()}</span>
                    </Tooltip>
                  }
                >
                  <>
                    {isOver === item.id && isAllowToCommunicate !== undefined && <Reaction closeModal={setIsOver} message={item} />}
                    {item.thread?.length > 0 && (
                      <MessageList
                        isItReply={true}
                        isAllowToCommunicate={isAllowToCommunicate}
                        messages={item.thread}
                        setReplyTo={setReplyTo}
                        handleReadMessage={handleReadMessage}
                        user={user}
                        loadMessages={loadMessages}
                        setUnread={setUnread}
                      />
                    )}
                    <div className="flex">
                      {!isItReply && replyTo?.id === item.id ? (
                        <div onClick={() => setReplyTo()} className="py-2 pl-1 font-bold select-none cursor-pointer hover:underline w-fit">
                          Cancel reply
                        </div>
                      ) : (
                        item.thread?.length > 0 &&
                        !item.isServiceMessage && (
                          <div
                            onClick={() => {
                              setReplyTo(() => {
                                setTimeout(() => srollToElem(document.getElementById(item.id)), 0);
                                return item.replyTo ? { id: item.replyTo.id, text: item.text } : item;
                              });
                            }}
                            className="py-2 pl-1 font-bold select-none cursor-pointer hover:underline w-fit"
                          >
                            Reply
                          </div>
                        )
                      )}
                    </div>
                  </>
                </Comment>
              </div>
            );
          })}
          {isItReply && messages.length > threadLoaded && (
            <div onClick={loadMoreComments} className="text-[#7F85A2] pl-[12px] font-bold select-none cursor-pointer hover:underline w-fit">
              Load older messages
            </div>
          )}
        </InfiniteScroll>
        {showImage && <ImageModal image={showImage} user={user} setShowImage={setShowImage} />}
      </>
    )
  );
};

const Messages = ({
  user,
  chatChannel,
  chatWhereUserMember,
  messages,
  setReplyTo,
  replyTo,
  controlFunctions,
  style,
  isAllowToCommunicate,
  loadMessages,
  users,
}: MessagesProps) => {
  const readMessage = (chatChannel: ChatChannel, message: ChatMessage, user: User, manual?: boolean, autoAlso?: boolean, handleAll?: boolean) => {
    let isChanged = false;
    if (manual) {
      const delIndex = message.unReadBy.findIndex((u: User) => u.id === user?.id);
      if (delIndex > -1) {
        message.unReadBy.splice(delIndex, 1);
        if (chatChannel?.manualUnread > 0) {
          isChanged = true;
          if (chatChannel.manualUnread - 1 <= 0) {
            chatChannel.manualUnread = 0;
          } else {
            chatChannel.manualUnread--;
          }
        }
      }
    }
    if (autoAlso && message.readBy.findIndex((u: User) => u.id === user.id) === -1) {
      message.readBy.push(user);
      isChanged = true;
      if (message.text.includes('@mention' + user.id) && typeof chatChannel.unreadMentions === 'number' && chatChannel.unreadMentions > 0)
        chatChannel.unreadMentions--;
      if (chatChannel?.unread > 0) {
        if (!manual && handleAll) {
          axiosChatInstance({
            url: `${process.env.REACT_APP_CHAT_API_BASE_URL}chat/read-message`,
            method: 'PUT',
            data: { id: message.id, manual: false },
          });
        }
        chatChannel.unread--;
      }
    }
    if (isChanged) {
      window.parent.postMessage({ type: CHAT_ACTION_MESSAGE, data: chatChannel }, '*');
    }
  };
  controlFunctions.handleAllRead = async (messages: ChatMessage[], isReadManual = true) => {
    if (!user) return;
    if (!chatChannel) return;
    await axiosChatInstance({
      url: `${process.env.REACT_APP_CHAT_API_BASE_URL}chat/read-all-messages`,
      method: 'PUT',
      data: { channelId: chatChannel.id },
    });
    if (isReadManual)
      axiosChatInstance({
        url: `${process.env.REACT_APP_CHAT_API_BASE_URL}chat/read-all-messages`,
        method: 'PUT',
        data: { channelId: chatChannel.id },
      });
    if (!messages) return;
    messages.forEach((message: ChatMessage) => {
      if (message.thread.length)
        message.thread.forEach((m: ChatMessage) => {
          readMessage(chatChannel, m, user, isReadManual, true, true);
        });
      readMessage(chatChannel, message, user, isReadManual, true, true);
    });
  };
  const setUnread = async (message: ChatMessage, user: User) => {
    axiosChatInstance({
      url: `${process.env.REACT_APP_CHAT_API_BASE_URL}chat/unread-message`,
      method: 'PUT',
      data: { id: message.id },
    });
    message.unReadBy.push(user);
    if (chatChannel?.manualUnread >= 0) chatChannel.manualUnread++;
    window.parent.postMessage({ type: CHAT_ACTION_MESSAGE, data: chatChannel }, '*');
    controlFunctions.forceUpdate();
  };
  const handleReadMessage = async (message: ChatMessage, user: User, manual?: boolean) => {
    axiosChatInstance({
      url: `${process.env.REACT_APP_CHAT_API_BASE_URL}chat/read-message`,
      method: 'PUT',
      data: { id: message.id, manual },
    });
    readMessage(chatChannel, message, user, manual, !manual);
    controlFunctions.forceUpdate();
  };
  return (
    <div className="messages-window" id="scrolableDiv" style={style}>
      {messages?.length ? (
        <MessageList
          users={users}
          loadMessages={loadMessages}
          isAllowToCommunicate={isAllowToCommunicate}
          messages={messages}
          replyTo={replyTo}
          setReplyTo={setReplyTo}
          handleReadMessage={handleReadMessage}
          setUnread={setUnread}
          user={user}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

export default Messages;
