import { Card, Space } from 'antd';
import { FC, ReactNode } from 'react';
import { ExternalLink } from '../../entities';
import { ReactComponent as IconFigma } from '../../images/icons/icon-figma.svg';
import { ReactComponent as IconDocs } from '../../images/icons/icon-google-docs.svg';

import './ProjectLinks.css';

const getIcon = (label: string): ReactNode => {
  if (label === 'Figma') return <IconFigma />;
  if (label === 'Google Drive') return <IconDocs />;
  return null;
};

const ProjectLinks: FC<{ links: ExternalLink[] }> = ({ links }) => {
  return (
    <div className="dashboard-links">
      <h2 className="dashboard-heading">Project links</h2>
      <Space size={16}>
        {links.map(link => {
          if (!link.link) return null;
          return (
            <Card key={link.label} className="dashboard-link-card">
              {getIcon(link.label)}
              <a href={link.link} className="dashboard-link-name" target="_blank" rel="noreferrer">
                {link.label}
              </a>
            </Card>
          );
        })}
      </Space>
    </div>
  );
};

export default ProjectLinks;
