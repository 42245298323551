import { Button, message, Popconfirm, Space, Table } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { UserPermisions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { nullableDataSorter } from '../../common/utils';
import { useUsers } from '../../dal';
import { User } from '../../entities';
import AddEditAccountForm from './AddEditAccountForm';

type Props = {
  clientId: string;
};

const ClientAccounts = ({ clientId }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editableClient, setEditableClient] = useState<User | undefined>();
  const { users = [], mutate } = useUsers(clientId);
  const { hasPermission } = usePermissions();

  const handleDelete = async (id: number) => {
    await axios.delete(`/users/${id}`);
    await mutate();
    message.success('Account deleted');
  };

  const handleEdit = (client: User) => {
    setEditableClient(client);
    setModalOpen(true);
  };

  const closeModal = () => {
    setEditableClient(undefined);
    setModalOpen(false);
  };

  const changeActive = async (client: User) => {
    await axios.patch(`/users/${client.id}`, { active: !client.active });
    await mutate();
    message.success('Saved');
  };

  return (
    <>
      <Table dataSource={users} size="small" rowKey="id" pagination={false}>
        <Table.Column dataIndex="name" title="Name" sorter={(a: User, b: User) => nullableDataSorter(a.name, b.name)} />
        <Table.Column dataIndex="email" title="Email" sorter={(a: User, b: User) => nullableDataSorter(a.name, b.name)} />
        <Table.Column
          title="Actions"
          render={(value, row: User) => (
            <Space>
              {hasPermission(UserPermisions.USER_DELETE) && (
                <Popconfirm title="Are you sure to delete this account?" onConfirm={() => handleDelete(row.id)} okText="Yes" cancelText="No">
                  <Button danger>Delete</Button>
                </Popconfirm>
              )}
              <Button type="primary" onClick={() => handleEdit(row)}>
                Edit
              </Button>
              <Button type="dashed" onClick={() => changeActive(row)}>
                {row.active ? 'Deactivate' : 'Activate'}
              </Button>
            </Space>
          )}
        />
      </Table>
      {modalOpen && editableClient && (
        <AddEditAccountForm
          modalOpen={modalOpen}
          closeModal={closeModal}
          clientId={clientId}
          initialValues={{ id: editableClient.id, name: editableClient.name, email: editableClient.email }}
        />
      )}
    </>
  );
};

export default ClientAccounts;
