import useSWR from 'swr';
import { FetchResult, User } from '../entities';

type Result = FetchResult & { users: User[] };

export function useUsers(clientId?: string, activeOnly: boolean = false): Result {
  const { data, error, mutate } = useSWR(`/users?activeOnly=${activeOnly}&${clientId ? `clientId=${clientId}` : ``}`);

  return {
    users: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
