import { Input, Row, Select } from 'antd';
import { ReactComponent as FilterIcon } from '../../../static/images/filter_icon.svg';
import { InternalPropjectTypes } from '../../../../components/project/ProjectsTable';
import UserSelector from '../../../../components/users/UserSelector';
import { Filters } from '../../../../components/project/ProjectFilters';

interface ProjectFilterProps {
  phrase: string;
  filter: Filters;
  showInternalProjects: InternalPropjectTypes;
  userFilter: null | number;
  onPhraseChange: (phrase: string) => void;
  onFilterChange: (filter: Filters) => void;
  onShowInternalProjectsChange: (showInternalProjects: InternalPropjectTypes) => void;
  onUserFilterChange: (userFilter: null | number) => void;
  onResetFilter: () => void;
}

const ProjectFilter = ({
  phrase,
  filter,
  showInternalProjects,
  userFilter,
  onPhraseChange,
  onFilterChange,
  onShowInternalProjectsChange,
  onUserFilterChange,
  onResetFilter,
}: ProjectFilterProps) => {
  return (
    <Row className="flex w-full !mt-[10px] flex-nowrap mb-[8px] px-[8px]">
      <Input
        className="search-filter-input bg-[#ECEFF3] rounded-[8px] h-[36px] text-sm mb-[10px] w-full"
        placeholder="Search..."
        value={phrase}
        onChange={e => onPhraseChange(e.target.value)}
        allowClear
      />
      <div
        className={`flex justify-center items-center filter-projects-button relative h-[36px] z-[1] w-[36px] min-w-[36px] ${
          (filter !== 'active' || showInternalProjects !== 'all' || userFilter) && 'filter-button-active'
        }`}
      >
        <FilterIcon className="filter-icon" />
        <span className="filter-text font-[600] text-ellipsis overflow-hidden whitespace-nowrap"></span>
        <div className="filter-project-hover absolute h-full w-full top-[10px]" />
        <div className="filter-projects-container absolute top-[40px] flex flex-col w-full rounded-b-[12px]">
          <span className="filter-reset-all" onClick={onResetFilter}>
            Reset all
          </span>
          <label htmlFor="select-status" className="font-[600] text-sm mb-1">
            Status
          </label>
          <Select
            id="select-status"
            className="select-filter"
            value={filter}
            onChange={e => {
              onFilterChange(e);
            }}
            getPopupContainer={trigger => trigger.parentElement}
          >
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="active">Active</Select.Option>
            <Select.Option value="draft">Draft</Select.Option>
            <Select.Option value="finished">Finished</Select.Option>
          </Select>
          <label htmlFor="switch-internal-projects" className="internal-project-label font-[600] text-sm mt-[16px] mb-1">
            Type
          </label>
          <Select
            id="switch-internal-projects"
            className="select-filter"
            value={showInternalProjects + ''}
            onChange={e => {
              onShowInternalProjectsChange(e as InternalPropjectTypes);
            }}
            getPopupContainer={trigger => trigger.parentElement}
          >
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="internal">Internal</Select.Option>
            <Select.Option value="client">Client</Select.Option>
          </Select>
          <label htmlFor="user-select-projects" className="font-[600] text-sm mt-[16px] mb-1">
            User
          </label>
          <UserSelector id="user-select-projects" allowClear value={userFilter} onChange={e => onUserFilterChange(e)} />
        </div>
      </div>
    </Row>
  );
};

export default ProjectFilter;
