
import { Button, Col, DatePicker, Popconfirm, Radio, Row, Space, Spin, message } from 'antd';
import { useImmerReducer } from "use-immer";
import { useDebouncedCallback } from 'use-debounce';
import { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import moment from 'moment';
import axios from 'axios';
import { GroupBy, prepareTableData } from './ProjectBudgetPlanner.model';
import { DataTable } from './ProjectBudgetPlanner.dataTable';
import { FinanceHeader } from './ProjectBudgetPlanner.financeHeader';
import { AddMember } from './ProjectBudgetPlanner.addMember';
import { useMembersRoles, useProjectFinance, useProjectsTimesheetSummary } from '../../dal';


const initialState: any = {
  budget: 0,
  roles: [],
  totalDesiredPercentage: 0,
  totalDesiredBudget: 0,
  totalDesiredHours: 0
};

function updateTotals(draft) {
  draft.totalDesiredBudget = draft.roles.reduce((acc, curr) => acc + (curr.desiredBudget || 0), 0);
  draft.totalDesiredPercentage = draft.roles.reduce((acc, curr) => acc + (curr.desiredPercentage || 0), 0);
  draft.totalDesiredHours = draft.roles.reduce((acc, curr) => acc + (curr.desiredHours || 0), 0);
}

function updateRoleTotals(role) {
  role.desiredPercentage = role.members.reduce((acc, cur) => acc + (cur.desiredPercentage || 0), 0);
  role.desiredBudget = role.members.reduce((acc, cur) => acc + (cur.desiredBudget || 0), 0);
  role.desiredHours = role.members.reduce((acc, cur) => acc + (cur.desiredHours || 0), 0);
}

function reducer(draft, action) {
  switch (action.type) {
    case "initial":
      if (action.initialState.budgetPlan !== undefined) {
        return action.initialState;
      }

      return {
        ...action.initialState,
        budgetPlan: action.initialState.roles.flatMap(r => r.members).map(r => ({
          userId: r.id,
          role: r.role,
          rate: r.rate,
          rateType: r.rateType,
          desiredHours: r.desiredHours,
          desiredBudget: r.desiredBudget,
          desiredPercentage: r.desiredPercentage,
          averageHourlyRate: r.averageHourlyRate
        }))
      };
    case "clear": 
      draft.roles = [];
      draft.budgetPlan = [];
      return draft;
    case "updateBudget":
      draft.budget = action.newValue;
      draft.roles.forEach(role => {
        role.members.forEach(m => {
          m.desiredBudget = m.desiredPercentage * action.newValue / 100;
          if (m.rate > 0) {
            m.desiredHours = +(m.desiredBudget / m.rate).toFixed(1);
          } else {
            m.desiredHours = 0;
          }
        });
        updateRoleTotals(role);
      });
      updateTotals(draft);

      draft.budgetPlan.forEach(p => {
        p.desiredBudget = action.newValue * p.desiredPercentage / 100;
        p.desiredHours = p.rateType === 'monthly'
          ? (p.averageHourlyRate > 0 ? +(p.desiredBudget / p.averageHourlyRate).toFixed(1) : 0)
          : (p.rate > 0 ? +(p.desiredBudget / p.rate).toFixed(1) : 0);
      });

      break;
    case "desiredPercentageChanged":
      {
        const item = draft.budgetPlan.find(bp => bp.role === action.role
          && bp.userId === action.userId
          && bp.rateType === action.rateType
          && (bp.rate === action.rate || bp.rateType === 'montly'));

        const desiredBudget = action.budget * action.newValue / 100;
        const desiredHours = action.rateType === 'monthly'
          ? (action.averageHourlyRate > 0 ? +(desiredBudget / action.averageHourlyRate).toFixed(1) : 0)
          : (action.rate > 0 ? +(desiredBudget / action.rate).toFixed(1) : 0);

        if (item) {
          item.desiredPercentage = action.newValue;
          item.desiredBudget = desiredBudget;
          item.desiredHours = desiredHours;
        } else {
          draft.budgetPlan.push({
            userId: action.userId,
            role: action.role,
            rate: action.rate,
            rateType: action.rateType,
            desiredPercentage: action.newValue,
            desiredBudget,
            desiredHours,
            averageHourlyRate: action.averageHourlyRate
          });
        }
      }
      break;

    case "desiredHoursChanged":
      {
        const item = draft.budgetPlan.find(bp => bp.role === action.role
          && bp.userId === action.userId
          && bp.rateType === action.rateType
          && (bp.rate === action.rate || bp.rateType === 'montly'));

        const desiredHours = action.newValue;
        const desiredBudget = desiredHours * (action.rateType === 'monthly' ? action.averageHourlyRate : action.rate);
        const desiredPercentage = Math.round(desiredBudget / action.budget * 100 * 10) / 10;

        if (item) {
          item.desiredPercentage = desiredPercentage;
          item.desiredBudget = desiredBudget;
          item.desiredHours = desiredHours;
        } else {
          draft.budgetPlan.push({
            userId: action.userId,
            role: action.role,
            rate: action.rate,
            rateType: action.rateType,
            desiredPercentage,
            desiredBudget,
            desiredHours,
            averageHourlyRate: action.averageHourlyRate
          });
        }
      }
      break;

    case "userAdded":
      {
        const item = draft.budgetPlan.find(bp => bp.role === action.role
          && bp.userId === action.userId
          && bp.rateType === action.rateType
          && (bp.rate === action.rate || bp.rateType === 'montly'));
        if (!item) {
          draft.budgetPlan.push({
            userId: action.userId,
            role: action.role,
            rate: action.rate,
            rateType: action.rateType,
            averageHourlyRate: action.averageHourlyRate,
            desiredPercentage: 0,
            desiredBudget: 0,
            desiredHours: 0
          });
        }
      }
      break;

    case "userDeleted":
      draft.budgetPlan = draft.budgetPlan.filter(bp => !(bp.role === action.role
        && bp.userId === action.userId
        && bp.rateType === action.rateType
        && (bp.rate === action.rate || bp.rateType === 'montly')));
      break;

  }
}

type Props = {
  projectId: string;
};

const ProjectBudgetPlanner = ({ projectId }: Props) => {
  const { roles } = useMembersRoles();
  const [state, dispatchInternal] = useImmerReducer<any, any>(reducer, {});
  const [saved, setSaved] = useState(true);
  const [viewMode, setViewMode] = useState<GroupBy>('role');
  const { finance, mutate: mutateFinance } = useProjectFinance(projectId);
  const [dateRange, setDateRange] = useState<any>([null, null]);

  const saveNewDesiredBudget = useCallback(async (value: number) => {
    const { data } = await axios.put(`/projects/${projectId}/finance`, { desiredProfit: 100 - Number(value) });
    await mutateFinance();
    return data;
  }, [projectId, mutateFinance]);

  // Data 

  const { timesheets } = useProjectsTimesheetSummary(projectId, dateRange[0], dateRange[1]);
  const { data: users } = useSWR(`/users/projects`);
  const { data: rates } = useSWR(`/users/rates`);

  const showRemainingColumns = (!dateRange[0] && !dateRange[1]);

  // Callbacks

  const saveDebounced = useDebouncedCallback(() => {
    axios
      .post(`/projects/${projectId}/budget`, state)
      .then(() => {
        setSaved(true);
      });
  }, 2000);

  useEffect(() => {
    const stateWithAllRoles = {
      ...initialState,
      roles: roles.map(r => ({
        key: r,
        type: 'role',
        name: r,
        desiredPercentage: 0,
        desiredBudget: 0,
        desiredHours: 0,
        members: []
      }))
    };

    if (!state.roles) {
      axios
        .get(`/projects/${projectId}/budget`)
        .then(r => r.data)
        .then(r => dispatchInternal({ type: 'initial', initialState: r.data?.budget || stateWithAllRoles }));
    }
  }, [dispatchInternal, projectId, roles, state.roles]);

  const dispatch = useCallback((data) => {
    setSaved(false);
    dispatchInternal(data);
    saveDebounced();
  }, [dispatchInternal, saveDebounced]);

  const onFinancesChange = useCallback(async (values: any) => {
    const { data } = await axios.put(`/projects/${projectId}/finance`, values);
    message.success('Data saved');
    mutateFinance();
    dispatch({ type: "updateBudget", newValue: data.data.budget * data.data.desiredBudget / 100 });
  }, [mutateFinance, projectId, dispatch]);

  const updateDesiredProfit = useCallback(async (value: number) => {
    const { data } = await saveNewDesiredBudget(value);
    dispatch({ type: "updateBudget", newValue: value * (data.budget || 0) / 100 });
  }, [dispatch, saveNewDesiredBudget]);

  if (!users || !rates || !timesheets || !state.roles || !finance)
    return <Spin />;

  const ratesDict = Object.fromEntries(rates.data.map(u => [u.id, {
    rateValue: u.rateValue,
    averageHourlyRate: u.averageHourlyRate,
    rateType: u.rateType,
    name: u.name,
    picture: u.picture
  }]));


  let viewByMemberData = {};

  state.roles.forEach(r => {
    r.members.reduce((acc, cur) => {
      if (!acc[cur.name]) {
        acc[cur.name] = {
          key: cur.name,
          name: cur.name,
          picture: cur.picture,
          type: "role",
          members: []
        };
      }
      acc[cur.name].members.push({
        key: r.name,
        id: cur.id,
        name: r.name,
        rate: cur.rate,
        role: cur.name,
        type: "member",
        rateType: cur.rateType,
        desiredHours: cur.desiredHours,
        desiredBudget: cur.desiredBudget,
        desiredPercentage: cur.desiredPercentage
      })
      return acc;
    }, viewByMemberData);
  });

  viewByMemberData = Object.values(viewByMemberData)

  const timesheetTotals = timesheets.reduce((acc, cur) => {
    acc.minutes += +cur.totalMinutes;
    acc.cost += +cur.totalCost;
    return acc;
  }, { minutes: 0, cost: 0 });

  const tableData = prepareTableData(state.budgetPlan, timesheets, viewMode as GroupBy, ratesDict);

  return (
    <>
      <FinanceHeader finance={finance} timesheetTotals={timesheetTotals} onUpdate={onFinancesChange} onDesiredProditUpdate={updateDesiredProfit} saved={saved} />
      <Row justify="space-between" style={{ marginTop: '16px', marginBottom: '16px' }}>
        <Col>
          <AddMember users={rates.data} onAdd={({ role, userId, rate, rateType, averageHourlyRate }) => dispatch({ type: "userAdded", userId, rate, role, rateType, averageHourlyRate })} />
        </Col>
        <Col>
          <Space>
            <Popconfirm title="Do you want to clear the planner (desired percentages and hours only)?" onConfirm={() => dispatch({ type: "clear" })}>
              <Button type="text" danger>Clear planner</Button>
            </Popconfirm>
            <DatePicker.RangePicker
              value={dateRange}
              onChange={(values) => setDateRange(values || [null, null])}
              renderExtraFooter={() => <Row justify="space-between">
                <Col>
                  <Button type="link" onClick={() => setDateRange([moment().startOf('month'), moment().endOf('month')])}>This month</Button>
                  <Button type="link" onClick={() => setDateRange([moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')])}>Previous month</Button>
                  <Button type="link" onClick={() => setDateRange([moment().subtract(30, 'days'), moment()])}>Last 30 days</Button>
                  <Button type="link" onClick={() => setDateRange([moment().startOf('year'), moment().endOf('year')])}>This year</Button>
                </Col>
                <Col>
                  <Button type="link" onClick={() => setDateRange([null, null])}>Clear</Button>
                </Col>
              </Row>}
            />

            <Radio.Group
              options={[
                { label: 'Group by role', value: 'role' },
                { label: 'Group by member', value: 'member' }
              ]}
              onChange={e => setViewMode(e.target.value)}
              value={viewMode}
              optionType="button"
            />
          </Space>
        </Col>
      </Row>
      <DataTable
        dataSource={tableData}
        viewMode={viewMode}
        showRemainingColumns={showRemainingColumns}
        onDesiredPercentageChanged={(newValue, role, userId, rate, rateType) => dispatch({
          type: "desiredPercentageChanged", 
          role,
          userId,
          rate,
          rateType,
          newValue,
          averageHourlyRate: ratesDict[userId].averageHourlyRate,
          budget: finance.budget * finance.desiredBudget / 100
        })}
        onDesiredHoursChanged={(newValue, role, userId, rate, rateType) => dispatch({
          type: "desiredHoursChanged",
          role,
          userId,
          rate,
          rateType,
          newValue,
          averageHourlyRate: ratesDict[userId].averageHourlyRate,
          budget: finance.budget * finance.desiredBudget / 100
        })}
        onDelete={(role, userId, rate, rateType) => dispatch({ type: "userDeleted", role, userId, rate, rateType })}
      />
    </>
  );
};

export default ProjectBudgetPlanner;
