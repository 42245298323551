import React from 'react';
import { Avatar, List, Modal } from 'antd';
import { User } from '../../../entities';
import { UserChannel } from '../../../entities/chat';
import { axiosChatInstance } from '../../../chat_main';

interface Props {
  user?: User;
  allUsers: User[];
  members: UserChannel[];
  channelId: string;
  isModalOpen: boolean;
  isAbleToRemove: boolean;
  setIsModalOpen: (value: boolean) => void;
}

const ChannelMembersModal: React.FunctionComponent<Props> = ({ user, members, channelId, isModalOpen, isAbleToRemove, setIsModalOpen }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const removeUserFromChat = (data: UserChannel) => {
    axiosChatInstance({
      url: `${process.env.REACT_APP_CHAT_API_BASE_URL}chat/${channelId}/remove-member`,
      method: 'POST',
      data: {
        userId: data.user.id,
      },
    });
  };

  return (
    <Modal title="Members" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <List
        dataSource={members}
        renderItem={item => (
          <List.Item
            actions={[
              isAbleToRemove && user?.id !== item.user.id && (
                <span
                  className=" hover:text-blue-600 cursor-pointer"
                  key="list-loadmore-edit"
                  onClick={() => {
                    removeUserFromChat(item);
                  }}
                >
                  remove
                </span>
              ),
            ]}
          >
            <List.Item.Meta avatar={<Avatar src={item.user.picture} />} title={item.user.name} />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default ChannelMembersModal;
