export type BudgetPlanItem = {
  userId: number;
  role: string;
  rate: number;
  desiredHours: number;
  desiredBudget: number;
  desiredPercentage: number;
  averageHourlyRate: number;
};

export type TimesheetSummaryItem =
  {
    userId: number;
    ratePerHour: number;
    category: string;
    totalMinutes: number;
    totalCost: number;
    rateType: RateType;
  };

export type TableRow = {
  key: string;
  isParent: boolean;
  entityType: GroupBy;
  role: string;
  userId: number;
  name: string;
  picture?: string;
  rate: number;
  rateType?: RateType;
  averageHourlyRate: number;
  desiredPercentage: number;
  desiredHours: number;
  desiredBudget: number;
  totalMinutes: number;
  totalCost: number,
  children?: TableRow[];
}
export type GroupBy = 'role' | 'member';
export type RateType = 'hourly' | 'monthly';

export type UsersDict = {
  [id: number]: {
    name: string;
    picture: string;
    rateType: RateType;
    rateValue: number;
    averageHourlyRate: number;
  }
};

const groupArrayBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function prepareTableData(
  budgetPlan: BudgetPlanItem[],
  timesheetSummary: TimesheetSummaryItem[],
  groupBy: GroupBy,
  users: UsersDict): TableRow[] {

  
  // Step 1: Merge budgetPlan with timesheetSummary

  let mergedItems: TableRow[] = budgetPlan.map(b => ({
    ...b,
    key: `${b.role}_${b.userId}_${b.rate}`,
    name: users[b.userId].name,
    picture: users[b.userId].picture,
    rateType: users[b.userId].rateType,
    totalMinutes: 0,
    totalCost: 0,
    isParent: false,
    entityType: 'member',
    
  }));

  timesheetSummary.forEach(t => {
    let idx = mergedItems.findIndex(o => o.userId === t.userId 
      && o.role === t.category 
      && o.rateType === t.rateType
      && (o.rate === t.ratePerHour || t.rateType === 'monthly'));
    if (idx > -1) {
      mergedItems[idx].totalCost = t.totalCost;
      mergedItems[idx].totalMinutes = t.totalMinutes;
    } else {
      mergedItems.push({
        key: `${t.category}_${t.userId}_${t.ratePerHour}`,
        userId: t.userId,
        name: users[t.userId].name,
        picture: users[t.userId].picture,
        rateType: t.rateType,
        role: t.category,
        rate: t.rateType === 'hourly' ? t.ratePerHour : users[t.userId].rateValue,
        averageHourlyRate: users[t.userId].averageHourlyRate,
        desiredBudget: 0,
        desiredHours: 0,
        desiredPercentage: 0,
        totalCost: t.totalCost,
        totalMinutes: t.totalMinutes,
        isParent: false,
        entityType: 'member'
      });
    };
  });

  // Step 2: Group

  const groupped = groupArrayBy(mergedItems, groupBy === 'role' ? 'role' : 'userId');

  // Step 3: Prepare for table usage

  let output: TableRow[] = [];
  for (const [key, items] of Object.entries(groupped)) {
    const tableRow = items as TableRow[];
    output.push({
      key,
      entityType: groupBy,
      role: '',
      userId: 0,
      rate: 0,
      averageHourlyRate: 0,
      name: groupBy === 'member' ? users[+key].name : key,
      picture: groupBy === 'member' ? users[+key].picture : undefined,
      isParent: true,
      children: tableRow.map(t => ({...t, entityType: groupBy === 'member' ? 'role' : 'member'})),
      desiredPercentage: tableRow.reduce((acc, cur) => acc + cur.desiredPercentage, 0),
      desiredHours: tableRow.reduce((acc, cur) => acc + cur.desiredHours, 0),
      desiredBudget: tableRow.reduce((acc, cur) => acc + cur.desiredBudget, 0),
      totalCost: tableRow.reduce((acc, cur) => acc + +cur.totalCost, 0),
      totalMinutes: tableRow.reduce((acc, cur) => acc + +cur.totalMinutes, 0)
    });
  }

  // Step 4: Order by name

  output.sort((a: TableRow, b: TableRow) => a.name > b.name ? 1 : -1)

  return output;
}