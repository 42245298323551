import { useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { Layout, Avatar } from 'antd';
import { useSession } from '../../dal';
import logo from '../../images/logo-black.svg';

import './ClientLayout.css';
import ClientProjects from '../../clientComponents/ClientProject.tsx/ClientProjects';

const { Content, Sider } = Layout;

const ClientLayout = ({ children }: { children: ReactNode; isFullPage?: boolean; overrideBackgroundContainer?: boolean }) => {
  const { session } = useSession();
  const [name, setName] = useState('');
  const [profileImg, setProfileImg] = useState('');

  useEffect(() => {
    if (!session) return;
    setProfileImg(session.picture);
    setName(session.name);
  }, [session]);

  return (
    <Layout className="client-layout">
      <Sider width={280} className="client-sidebar">
        <div className="client-sidebar-container">
          <img src={logo} alt="Design in DC logo" width="81px" height="40px" className="client-sidebar-logo" />
          <Avatar size={56} src={profileImg} />
          <h3 className="client-sidebar-name">{name}</h3>
          <ClientProjects />
        </div>
      </Sider>
      <Layout className="client-content-layout">
        <Content className="client-content">
          <div>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ClientLayout;
