import { useEffect, useState } from 'react';
import { useProjectMembersNotifications } from '../../common/useProjectMembersNotifications';
import { Task } from '../../entities';
import UsersMultiSelector from '../users/UsersMultiSelector';

interface Props {
  task: Task;
  onTaskChange: (data: Partial<Task>) => Promise<void>;
}

const TaskAssignees = ({ task, onTaskChange }: Props) => {
  const [ids, setIds] = useState<number[] | undefined>();
  const { canAddMembers, showNotificationForAssignees } = useProjectMembersNotifications(task.project!.id);

  useEffect(() => {
    if (task.assignees) {
      // because task is object with nested arrays & objects
      // we have to refresh state on every prop change to force rerender
      setIds(task.assignees.map(a => a.id));
    }
  }, [task]);

  const handleChange = (values: number[]) => {
    setIds(values);
    if (canAddMembers && values) {
      showNotificationForAssignees(values);
    }
    onTaskChange({ id: task.id, assignees: values?.map(i => ({ id: i })) as any });
  };

  return <UsersMultiSelector value={ids} onChange={handleChange} style={{ width: '100%' }} />;
};

export default TaskAssignees;
