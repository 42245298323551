import useSWR from 'swr';
import { FetchResult } from '../entities';
import moment from 'moment';

type Result = FetchResult & { timesheets: any };

export function useProjectsTimesheetSummary(id?: string, fromDate?: moment.Moment, toDate?: moment.Moment): Result {
  let queryString = (fromDate && toDate) ? `?from=${fromDate?.format('YYYY-MM-DD')}&to=${toDate?.format('YYYY-MM-DD')}` : '';
  const { data, error, mutate } = useSWR(id ? `/projects/${id}/timesheet-summary${queryString}` : null);

  return {
    timesheets: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
