import { Card, Col, Row } from 'antd';
import { customCardHeadStyle } from '../../common/styles';
import { displayHoursFromNumber, formatDateTime, getTotalHours } from '../../common/utils';
import { datePickerFormat } from '../../constants';
import { useProjectsTimesheets } from '../../dal/useProjectsTimesheet';
import { useProjectTasks } from '../../dal/useProjectTasks';
import { Project, Task, TaskPhase } from '../../entities';

type Props = {
  project: Project;
  onTabChange: (tab: string) => void;
};

function getAllTasks(phases: TaskPhase[], unassigned: Task[]): Task[] {
  const data = [...unassigned];
  phases.forEach(p => p.tasks && data.push(...p.tasks));
  return data;
}

const ProjectOverview = ({ project, onTabChange }: Props) => {
  const { phases, unassigned } = useProjectTasks(project.id);
  const { timesheets } = useProjectsTimesheets(project.id);

  const allTasks = getAllTasks(phases, unassigned);
  const tasksNumber = allTasks.length;
  const completedTasksNumber = allTasks.filter(t => t.status === 'done').length;

  return (
    <Row gutter={16}>
      <Col span="6">
        <Card
          size="small"
          title="Total hours spent"
          className="cursor-pointer"
          headStyle={customCardHeadStyle}
          onClick={() => onTabChange('timesheet')}
        >
          {displayHoursFromNumber(getTotalHours(timesheets))} hr
        </Card>
      </Col>
      <Col span="6">
        <Card
          size="small"
          title="Members on this project"
          className="cursor-pointer"
          headStyle={customCardHeadStyle}
          onClick={() => onTabChange('members')}
        >
          {project.members.length} members
        </Card>
      </Col>
      <Col span="6">
        <Card size="small" title="Tasks completed" className="cursor-pointer" headStyle={customCardHeadStyle} onClick={() => onTabChange('tasks')}>
          {completedTasksNumber} / {tasksNumber}
        </Card>
      </Col>
      <Col span="6">
        <Card size="small" title="Targeted end date" headStyle={customCardHeadStyle}>
          {project.estimatedEndDate ? formatDateTime(project.estimatedEndDate, datePickerFormat) : 'Date not set'}
        </Card>
      </Col>
    </Row>
  );
};

export default ProjectOverview;
