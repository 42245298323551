import { useSession } from '../../dal';
import { PermissionsType } from './permissions';

export function usePermissions(): { hasPermission: (p: PermissionsType) => boolean; permissions: PermissionsType[] } {
  const { session } = useSession();
  const permissions = session?.permissions || [];

  const hasPermission = (permission: PermissionsType) => {
    return permissions.includes(permission);
  };

  return { permissions, hasPermission };
}
