const CHAT_ACTION_MESSAGE = "CHAT_ACTION_MESSAGE";
const POST_MESSAGE_NOTIFICATION = "POST_MESSAGE_NOTIFICATION";
const ALL_CHAT_INFO = "ALL_CHAT_INFO";

export default class DDChat {

  #elID = '';
  #chatView = '';
  #iFrameURL = '';
  #channelName = '';
  #allowComment = true;
  #chatId = '';
  #name = '';
  #picture = '';
  #token = '';
  #iframeRef;
  #unreaded = {};

  lessDataURL = () => `${this.#iFrameURL}#chat-id=${this.#chatId}&token=${this.#token}&name=${encodeURIComponent(this.#name)}&picture=${encodeURIComponent(this.#picture)}`;
  uRL = () => `${this.#iFrameURL}#chat-id=${this.#chatId}&token=${this.#token}&name=${encodeURIComponent(this.#name)}&picture=${encodeURIComponent(this.#picture)}&chatView=${this.#chatView}&channel-name=${this.#channelName}&${this.#allowComment ? 'allow-comment' : ''}`;

  #replaceIdOnChatView(budgeData) {
    if (budgeData.id && !budgeData.chatView) {
      budgeData.chatView = budgeData.id;
      delete budgeData.id;
    }
    return budgeData;
  }

  #chatArrayToObject(chatArray) {
    let chatObject = {};
    if (chatArray) {
      for (let key in chatArray) {
        const chat = this.#replaceIdOnChatView(chatArray[key]);
        if (chat.type === 'private' || chat.type === 'individual') {
          if (chatObject['direct-chats']) {
            if (chat.unread > 0) {
              chatObject['direct-chats'].unread = chatObject['direct-chats'].unread + 1;
              chatObject['direct-chats'].unreadChats = { ...chatObject['direct-chats'].unreadChats, [chat.chatView]: chat.unread };
            }
            if (chat.manualUnread > 0) {
              chatObject['direct-chats'].manualUnread = chatObject['direct-chats'].manualUnread + 1;
              chatObject['direct-chats'].manualChats = { ...chatObject['direct-chats'].manualChats, [chat.chatView]: chat.manualUnread };
            }
          } else {
            chatObject['direct-chats'] = {
              unread: chat.unread ? 1 : 0,
              manualUnread: chat.manualUnread ? 1 : 0,
              chatView: "direct-chats",
              unreadChats: chat.unread ? { [chat.chatView]: 1 } : {},
              manualChats: chat.manualUnread ? { [chat.chatView]: 1 } : {},
            }
          }
        } else {
          chatObject[chat.chatView] = chat;
        }
      }
      return chatObject;
    } return false;
  }

  constructor({ el = '', chatId, name = "", picture = "", onNotify, onLoad, iFrameURL, token }) {
    // request notificatnio permission on porject loading
    Notification.requestPermission();

    window.addEventListener('message', ({ data, source, origin }) => {
      if (data?.type === POST_MESSAGE_NOTIFICATION) {
        const notifyData = data.data
        const title = `New message from: ${notifyData.author.name}`;
        if ((notifyData.message.channel.type === "global" || notifyData.message.channel.isNameEdited) && notifyData.isYouMentioned?.length > 0) {
          new Notification(title, { body: notifyData.message.text, icon: notifyData.author.picture });
        } else if (notifyData.message.channel.type === "individual" || (notifyData.message.channel.type === "private" && !notifyData.message.channel.isNameEdited)) {
          new Notification(title, { body: notifyData.message.text, icon: notifyData.author.picture });
        }
      }

      if (data?.type === CHAT_ACTION_MESSAGE) {
        if (data.data.type === 'global') {
          onNotify(this.#replaceIdOnChatView(data.data));
        } else {
          const chat = data.data;
          const directChats = this.#unreaded['direct-chats'] || {
            unread: 0,
            manualUnread: 0,
            chatView: "direct-chats",
            unreadChats: {},
            manualChats: {},
          }
          if (!directChats.unreadChats[chat.id] || chat.unread !== directChats.unreadChats[chat.id]) {
            if (!directChats.unreadChats[chat.id] && chat.unread > 0) {
              directChats.unread = directChats.unread + 1;
              directChats.unreadChats = { ...directChats.unreadChats, [chat.id]: chat.unread };
            } else if (chat.unread < directChats.unreadChats[chat.id]) {
              if (directChats.unreadChats[chat.id] === 1) {
                directChats.unread = directChats.unread >= 1 ? directChats.unread - 1 : 0;
                delete directChats.unreadChats[chat.id];
              } else {
                directChats.unreadChats[chat.id]--;
              }
            }
          }
          if (!directChats.manualChats[chat.id] || chat.manualUnread !== directChats.manualChats[chat.id]) {
            if (!directChats.manualChats[chat.id] && chat.manualUnread > 0) {
              directChats.manualUnread = directChats.manualUnread + 1;
              directChats.manualChats = { ...directChats.manualChats, [chat.id]: chat.manualUnread };
            } else if (chat.manualUnread < directChats.manualChats[chat.id]) {
              if (directChats.manualChats[chat.id] === 1) {
                directChats.manualUnread = directChats.manualUnread >= 1 ? directChats.manualUnread - 1 : 0;
                delete directChats.manualChats[chat.id];
              } else {
                directChats.manualChats[chat.id]--;
              }
            }
          }
          this.#unreaded['direct-chats'] = directChats;
          onNotify(directChats);
        }
      }
      if (data?.type === ALL_CHAT_INFO) {
        this.#unreaded = this.#chatArrayToObject(data.data);
        onLoad(this.#unreaded);
      }
    });

  }

  getUnreaded() {
    return this.#unreaded;
  }

  close() {
    this.#chatView = '';
    this.#channelName = '';
    this.#allowComment = false;
    if (this.#iframeRef) {
      this.#updateIframeURL(false);
    }
  }

  #updateIframeURL(isFullLoad) {
    if (!this.#iframeRef) {
      this.#createIframe(isFullLoad);
    } else {
      this.#iframeRef.setAttribute('src', isFullLoad ? this.uRL() : this.lessDataURL());
      this.#iframeRef.style.display = isFullLoad ? "block" : "none";
      if (isFullLoad && this.#elID) {
        this.#updateIFramePosition();
      }
    }
  }

  #updateIFramePosition(attempt = 0) {
    const bodyRect = document.body.getBoundingClientRect(),
      parentElem = document.getElementById(this.#elID);
    const elemRect = parentElem?.getBoundingClientRect();
    if (elemRect && parentElem.clientWidth >= 0 && parentElem.clientHeight >= 0) {
      this.#iframeRef.style.width = parentElem.clientWidth + 'px';
      this.#iframeRef.style.height = parentElem.clientHeight + 'px';
      this.#iframeRef.style.top = elemRect.top - bodyRect.top + 'px';
      this.#iframeRef.style.left = elemRect.left - bodyRect.left + 'px';
    } else if (attempt < 3) setTimeout(() => this.#updateIFramePosition(attempt + 1), 1 + attempt * 10);
  }

  #createIframe(isFullLoad) {
    const iframe = document.createElement('iframe');
    iframe.id = 'iFrameChat';
    iframe.setAttribute('src', isFullLoad ? this.uRL() : this.lessDataURL());
    iframe.setAttribute('frameBorder', '0');
    iframe.style.position = 'absolute';
    iframe.style.display = isFullLoad ? "block" : "none";
    document.body.appendChild(iframe);
    this.#iframeRef = iframe;
  }

  setChannel({ chatView, channelName, allowComment, picture, el, chatId, name, token }) {
    if (typeof chatView !== "undefined") this.#chatView = chatView;
    if (typeof channelName !== "undefined") this.#channelName = channelName;
    if (typeof allowComment !== "undefined") this.#allowComment = allowComment;
    if (typeof picture !== "undefined") this.#picture = picture;
    if (typeof el !== "undefined") this.#elID = el;
    if (chatId) this.#chatId = chatId;
    if (token) this.#token = token;
    if (name) this.#name = name;

    if (chatView && chatView !== '') {
      if (!this.#iframeRef) {
        this.#createIframe(true);
      } else {
        setTimeout(() => this.#updateIframeURL(true), 0)
      }
    } else {
      if (!this.#iframeRef) {
        this.#createIframe(false);
      } else {
        this.#updateIframeURL(false);
      }
    }
  }

  getChannel() {
    return this.#chatView;
  }
}
