import { Select } from 'antd';
import { ApprovalsPermissions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { useSession } from '../../dal';
import { useProjectTasks } from '../../dal/useProjectTasks';
import { Task } from '../../entities';

const TaskSelector = (props: any) => {
  const { session } = useSession();
  const { hasPermission } = usePermissions();
  const { phases = [], unassigned = [], isLoading } = useProjectTasks(props.project);
  const phasesTasks = phases.reduce((prev: any, curr) => {
    if (curr.tasks) {
      return [...prev, ...curr.tasks];
    }
    return prev;
  }, []);

  const options: Task[] = hasPermission(ApprovalsPermissions.APPROVALS_WRITE)
    ? [...phasesTasks, ...unassigned]
    : [...phasesTasks, ...unassigned].filter(t => {
        const checkUser = t.assignees.filter(a => a.id === session?.id);
        return checkUser.length > 0;
      });

  if (!props.project && isLoading) {
    return <>Project not selected</>;
  }

  if (!isLoading && options.length === 0) {
    return (
      <>
        No tasks in this <a href={`/projects/${props.project}`}>project</a>
      </>
    );
  }

  return (
    <Select
      {...props}
      showSearch
      placeholder="Select task"
      options={options.map(o => ({ label: o.name, value: o.id }))}
      filterOption={(input, option) => {
        return `${option?.label}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    />
  );
};

export default TaskSelector;
