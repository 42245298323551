import useSWR from 'swr';
import { FetchResult, User } from '../entities';
import { PermissionsType } from '../common/usePermissions/permissions';
import { axiosChatInstance } from '../chat_main';

export type UserWithPermissions = User & { permissions: PermissionsType[] };
type Result = FetchResult & { session?: UserWithPermissions };

const fetcher = (url: string) => axiosChatInstance({ url, method: "GET" }).then(res => res.data);

export function useSession(searchParams?): Result {
  const { data, error, mutate } = useSWR<{ user: UserWithPermissions }>(`${process.env.REACT_APP_CHAT_API_BASE_URL}session?${searchParams}`, fetcher);

  return {
    session: data?.user,
    isLoading: !error && !data,
    isError: error || !data?.user,
    mutate,
  };
}
