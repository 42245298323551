import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Project, Task, User } from '../../entities';
import { ReactComponent as IconProjects } from '../../images/icons/icon-projects.svg';
import { ReactComponent as IconTeam } from '../../images/icons/icon-team.svg';
import { ReactComponent as IconMyTasks } from '../../images/icons/icon-my-tasks.svg';
import './Results.css';

type SearchResults = {
  projects: Project[];
  users: User[];
  tasks: Task[];
};

type Props = {
  query: string;
};

const Results = ({ query }: Props) => {
  const [results, setResults] = useState<SearchResults>({ projects: [], users: [], tasks: [] });
  useEffect(() => {
    async function fetchData() {
      const res = await axios.get<AxiosResponse<SearchResults>>(`/search?q=${query}`);
      setResults(res.data.data);
    }

    if (query) {
      fetchData();
    }
  }, [query]);

  const closeSearch = () => {
    const event = new KeyboardEvent('keydown', {
      bubbles: true,
      cancelable: true,
      key: 'k',
      ctrlKey: true,
      keyCode: 75,
    });
    document.dispatchEvent(event);
  };

  return (
    <>
      <p className="searches-header">Projects</p>
      {!!results && results.projects.length === 0 && <p>No results</p>}
      <ul className="search-results-list">
        {!!results &&
          results.projects.map(project => (
            <li key={project.id}>
              <Link onClick={closeSearch} to={`/projects/${project.id}`}>
                <IconProjects className="search-results-icon" />
                {project.client?.name} - {project.name}
              </Link>
            </li>
          ))}
      </ul>

      <p className="searches-header">Tasks</p>
      {!!results && results.tasks.length === 0 && <p>No results</p>}
      <ul className="search-results-list">
        {!!results &&
          results.tasks.map(task => (
            <li key={task.id}>
              <Link to={`/tasks`}>
                <IconMyTasks className="search-results-icon" />
                {task.name}
              </Link>
            </li>
          ))}
      </ul>

      <p className="searches-header">People</p>
      {!!results && results.users.length === 0 && <p>No results</p>}
      <ul className="search-results-list">
        {!!results &&
          results.users.map(user => (
            <li key={user.id}>
              <Link to={`/users/${user.id}`}>
                <IconTeam className="search-results-icon" />
                {user.name}
              </Link>
            </li>
          ))}
      </ul>
    </>
  );
};

export default Results;
