import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Switch, Divider, Popconfirm, Row, Col, Spin, message, DatePicker, Table } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import MoneyInput from '../shared/MoneyInput';
import { useProjectFinance } from '../../dal';
import axios from 'axios';
import { useForm } from 'antd/lib/form/Form';
import { datePickerFormat } from '../../constants';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { ProjectPermissions } from '../../common/usePermissions/permissions';
import moment from 'moment';
import { Payment } from '../../entities';
import { formatCurrency } from '../../common/utils';

type Props = {
  projectId: string;
};

const ProjectFinances = ({ projectId }: Props) => {
  const { finance, mutate: mutateFinance } = useProjectFinance(projectId);
  const { hasPermission } = usePermissions();
  const [form] = useForm();
  const [budgetForm] = useForm();
  const [paidTotal, setPaidTotal] = useState<number | null>();
  const [remaining, setRemaining] = useState<number | null>();
  const [budget, setBudget] = useState<number | null>();

  if (paidTotal || remaining || budget) {
    // will update, delete unused vars
  }

  const onFinish = async (values: any) => {
    const resp = await axios.put(`/projects/${projectId}/finance`, values);
    message.success('Data saved');
    return resp;
  };

  const updateTotal = useCallback((_: any, { payments, budgetDetails }: any) => {
    const budget = budgetDetails.reduce((acc: number, budgetItem: any) => (budgetItem?.value ? (+budgetItem?.value || 0) + acc : acc), 0);
    const paid = payments.reduce((acc: number, payment: any) => (payment?.paid ? (+payment?.value || 0) + acc : acc), 0);
    setPaidTotal(paid);
    setRemaining(budget - paid);
    setBudget(budget);
  }, []);

  useEffect(() => {
    if (finance) {
      updateTotal(null, finance);
    }
  }, [updateTotal, finance]);

  const updateTotalAfterPaymentChange = useCallback(
    (_: any, { payments }: any) => {
      updateTotal(null, { payments, budgetDetails: finance?.budgetDetails });
    },
    [updateTotal, finance],
  );

  useEffect(() => {
    form.resetFields();
  }, [form, projectId]);

  const submit = async () => {
    const resp = await onFinish({
      ...form.getFieldsValue(),
      ...budgetForm.getFieldsValue(),
    });
    await mutateFinance();
    updateTotal(null, resp.data.data);
    setBudget(null);
    setRemaining(null);
    setPaidTotal(null);
  };

  if (!finance) return <Spin />;

  return (
    <>
      <Divider orientation="left">Payments</Divider>
      
      {!hasPermission(ProjectPermissions.PROJECT_FINANCES_READ_COSTS) ? (
        <Table dataSource={finance.payments} size="small" key={new Date().toDateString()} pagination={false} style={{marginBottom: '16px'}}>
          <Table.Column title="Value" width="150px" render={(p: Payment) => formatCurrency(p.value)} />
          <Table.Column title="Paid" width="100px" render={(p: Payment) => (p.paid ? 'Paid' : 'Not paid')} />
          <Table.Column title="Client invoice link" width="250px" render={(p: Payment) => p.clientInvoiceLink && <a href={p.clientInvoiceLink} target="_blank" rel="noreferrer">Open in new window</a> } />
          <Table.Column title="Note" render={(p: Payment) => p.description || ''} ellipsis />
          <Table.Column title="Estimated pay date" width="150px" render={(p: Payment) => (p.estimatedPayDate ? moment(p.estimatedPayDate).format(datePickerFormat) : '')} />
          <Table.Column title="Sent date" width="150px" render={(p: Payment) => (p.sentDate ? moment(p.sentDate).format(datePickerFormat) : '')} />
          <Table.Column title="Paid date" width="150px" render={(p: Payment) => (p.paidDate ? moment(p.paidDate).format(datePickerFormat) : '')} />
        </Table>
      ) : (
        <Form onFinish={submit} autoComplete="off" initialValues={finance} onValuesChange={updateTotalAfterPaymentChange} form={form}>
          <Form.List name="payments">
            {(fields: any, { add, remove }) => (
              <>
                <Row gutter={16}>
                  <Col span="3">Value</Col>
                  <Col span="1">Paid</Col>
                  <Col span="3">Internal invoice</Col>
                  <Col span="3">Client invoice</Col>
                  <Col span="3">Note</Col>
                  <Col span="3">Estimated pay date</Col>
                  <Col span="3">Sent date</Col>
                  <Col span="3">Paid date</Col>
                </Row>
                <Divider />
                {fields.map((field: any) => (
                  <Row key={field.key} gutter={16}>
                    <Col span="3">
                      <Form.Item {...field} name={[field.name, 'value']} rules={[{ required: true, message: 'Missing value' }]}>
                        <MoneyInput />
                      </Form.Item>
                    </Col>
                    <Col span="1">
                      <Form.Item {...field} name={[field.name, 'paid']} valuePropName="checked">
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col span="3">
                      <Form.Item {...field} name={[field.name, 'invoiceLink']}>
                        <Input
                          style={{ width: '100%' }}
                          addonAfter={
                            <a href={(finance.payments[field.key] || {}).invoiceLink} target="_blank" rel="noreferrer">
                              <Button type="text" size="small">
                                Go
                              </Button>
                            </a>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span="3">
                      <Form.Item {...field} name={[field.name, 'clientInvoiceLink']}>
                        <Input
                          style={{ width: '100%' }}
                          addonAfter={
                            <a href={(finance.payments[field.key] || {}).clientInvoiceLink} target="_blank" rel="noreferrer">
                              <Button type="text" size="small">
                                Go
                              </Button>
                            </a>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span="3">
                      <Form.Item {...field} name={[field.name, 'description']}>
                        <Input style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span="3">
                      <Form.Item {...field} name={[field.name, 'estimatedPayDate']}>
                        <DatePicker format={datePickerFormat} />
                      </Form.Item>
                    </Col>
                    <Col span="3">
                      <Form.Item {...field} name={[field.name, 'sentDate']}>
                        <DatePicker format={datePickerFormat} />
                      </Form.Item>
                    </Col>
                    <Col span="3">
                      <Form.Item {...field} name={[field.name, 'paidDate']}>
                        <DatePicker format={datePickerFormat} />
                      </Form.Item>
                    </Col>
                    <Col span="1">
                      <Popconfirm title="Are you sure to delete this payment?" onConfirm={() => remove(field.name)} okText="Yes" cancelText="No">
                        <Button type="text" icon={<MinusCircleOutlined />} />
                      </Popconfirm>
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col span="22">
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add payment
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col span="2" style={{ textAlign: 'right' }}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
        </Form>
      )}
    </>
  );
};

export default ProjectFinances;
