import { Select, Space, PageHeader, Switch } from 'antd';
import TimesheetTabs from '../Timesheet/TimesheetTabs';
import { useProjects } from '../../dal';
import { Project } from '../../entities';
import TimesheetTabsControls from '../Timesheet/TimesheetTabsControls';
import { useState } from 'react';
import { useTimesheetData } from '../../pages/timesheet/useTimesheetData';
import Title from 'antd/lib/typography/Title';
import { formatDateTime, getLastSaturday, getFirstDayOfMonth } from '../../common/utils';
import TimesheetMonth from '../Timesheet/TimesheetMonth';
import moment from 'moment';
import { useTimesheetModal } from '../Timesheet/useTimesheetModal';
import TimesheetForm from '../Timesheet/TimesheetForm';
import { ApprovalsPermissions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';

const { Option } = Select;

function filterProjectsUserIsIn(projects: Project[], userId: number | string): Project[] {
  return projects.filter(p => p.members.filter(m => m.user.id === userId).length > 0);
}

interface Props {
  userId: number | string;
}

const UserTimesheet = ({ userId }: Props) => {
  const { hasPermission } = usePermissions();
  const { projects } = useProjects();
  const [projectId, setProjectId] = useState<string>('all');
  const [monthlyView, setMontlyView] = useState(false);
  const {
    getNextWeek,
    getPreviousWeek,
    getPreviousMonth,
    getNextMonth,
    userTimesheet,
    activeDay,
    setActiveDay,
    setStartDate,
    weekDays,
    user,
    refresh,
  } = useTimesheetData({
    userId,
    projectId,
    monthlyView,
  });
  const { entryId, modalOpen, openModal, setModalOpen, setEntryId } = useTimesheetModal();

  const setFilter = (value: string) => {
    setProjectId(value === 'all' ? 'all' : value);
  };

  const dayChange = (date: string) => {
    setActiveDay(date);
  };

  const monthlyViewChange = (checked: boolean) => {
    setMontlyView(checked);
    setStartDate(checked ? getFirstDayOfMonth() : getLastSaturday());
    setActiveDay(moment(checked ? getFirstDayOfMonth() : new Date()).format('MMM D, YYYY'));
  };

  return (
    <>
      <PageHeader
        title="Timesheet"
        tags={
          <div>
            <Switch checked={monthlyView} checkedChildren="Monthly" unCheckedChildren="Monthly" onChange={checked => monthlyViewChange(checked)} />
          </div>
        }
      >
        {projects && (
          <Space size={20}>
            <Select placeholder="Select project" onChange={setFilter} style={{ width: '250px' }}>
              <Option key="all" value="all">
                All
              </Option>
              {filterProjectsUserIsIn(projects, userId).map(project => (
                <Option key={project.id} value={project.id}>
                  {project.client?.name || <i>No client</i>}- {project.name}
                </Option>
              ))}
            </Select>
          </Space>
        )}
        {monthlyView ? (
          <div style={{ textAlign: 'center' }}>
            <Title level={5}>{formatDateTime(activeDay, 'MMMM, YYYY')}</Title>
            <Space>
              <TimesheetTabsControls onGetPrevious={getPreviousMonth} onGetNext={getNextMonth} />
            </Space>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Title level={5}>
              {formatDateTime(weekDays[0], 'D MMM')} - {formatDateTime(weekDays[6], 'D MMM')}
            </Title>
            <Space>
              <TimesheetTabsControls onGetPrevious={getPreviousWeek} onGetNext={getNextWeek} />
            </Space>
          </div>
        )}
      </PageHeader>
      {userTimesheet &&
        (monthlyView ? (
          <TimesheetMonth timesheets={userTimesheet} rates={user} activeDay={activeDay} />
        ) : (
          <TimesheetTabs
            displayFinancialData={true}
            addEntryEnabled={hasPermission(ApprovalsPermissions.APPROVALS_WRITE)}
            user={user}
            activeDay={activeDay}
            onDayChange={dayChange}
            timesheets={userTimesheet}
            days={weekDays}
            onEditClick={hasPermission(ApprovalsPermissions.APPROVALS_WRITE) ? openModal : undefined}
            refreshTimesheets={refresh}
          />
        ))}
      {modalOpen && entryId && (
        <TimesheetForm
          defaultDate={activeDay}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          entryId={entryId}
          setEntryId={setEntryId}
          refreshTimesheets={refresh}
        />
      )}
    </>
  );
};

export default UserTimesheet;
