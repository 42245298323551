import { Space, Switch, Button, Spin, Select, Statistic, Table, message, PageHeader, Typography, Modal, Popconfirm } from "antd";
import { CalculatorOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useCallback, useState } from "react";
import { formatCurrency } from "../../common/utils";
import { useUsers } from "../../dal";
import { usePayroll } from "../../dal/usePayroll";
import { useTimesheetReport } from "../../dal/useTimesheetReport";
import { TimesheetMonth, TimesheetReportItem } from "../../entities";
import TimesheetTabsControls from "../../components/Timesheet/TimesheetTabsControls";
import moment from "moment";

const Payroll = () => {
    const { users } = useUsers();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentTimesheet, setCurrentTimesheet] = useState<number | null>(null);
    const [activeDay, setActiveDay] = useState(moment().startOf('month'));
    const { report } = useTimesheetReport(currentTimesheet, activeDay.format('YYYY-MM-DD'));
    const { payroll, mutate } = usePayroll(activeDay.format('YYYY-MM-DD'));

    const onUpdate = useCallback(async (propName, propValue, id) => {
        setIsLoading(true);
        try {
            await axios.patch(`/payroll/${id}`, { [propName]: propValue });
            await mutate();
            message.success('Payroll updated');
        } catch {
            message.error('Invalid input');
        }
        setIsLoading(false);
    }, [mutate]);

    const add = useCallback(async () => {
        setIsLoading(true);
        await axios.post(`/payroll`, { currentMonth: activeDay.format('YYYY-MM-DD') });
        await mutate();
        message.success('Payroll entry added');
        setIsLoading(false);
    }, [mutate, activeDay]);

    const remove = useCallback(async (id) => {
        setIsLoading(true);
        try {
            await axios.delete(`/payroll/${id}`);
            await mutate();
            message.success('Payroll updated');
        } catch {
            message.error('Error occured');
        }
        setIsLoading(false);
    }, [mutate]);

    const regeneratePayroll = useCallback(async () => {
        setIsLoading(true);
        await axios.post(`/payroll/regenerate`, { currentMonth: activeDay.format('YYYY-MM-DD') });
        await mutate();
        message.success('Payroll updated');
        setIsLoading(false);
    }, [mutate, activeDay]);

    const openTimesheet = useCallback(async (id) => {
        setIsLoading(true);
        setCurrentTimesheet(id);
        setIsLoading(false);
    }, []);

    const getPreviousMonth = useCallback(() => {
        setActiveDay(activeDay.clone().subtract(1, 'month'));
        mutate();
    }, [activeDay, mutate]);

    const getNextMonth = useCallback(() => {
        setActiveDay(activeDay.clone().add(1, 'month'));
        mutate();
    }, [activeDay, mutate]);

    if (!payroll || !users)
        return (
            <div>
                <Spin />
            </div>
        );

    const totals = payroll.reduce((prev, curr) => {
        return {
            total: prev.total + curr.billAmount,
            totalCount: prev.totalCount + ((curr.billAmount > 0 || curr.user === null) ? 1 : 0),
            paid: prev.paid + (curr.isPaid ? curr.billAmount : 0),
            paidCount: prev.paidCount + (curr.isPaid ? 1 : 0),
            cleared: prev.cleared + (curr.isCleared ? curr.billAmount : 0),
            clearedCount: prev.clearedCount + (curr.isCleared ? 1 : 0),
            pending: prev.pending + (curr.approval === "pending" ? 1 : 0),
            approved: prev.approved + (curr.approval === "approved" ? 1 : 0),
            notApproved: prev.notApproved + (curr.approval === "not-approved" ? 1 : 0),
            blank: prev.blank + (!curr.approval && (curr.billAmount > 0 || curr.user === null) ? 1 : 0),
        };
    }, { total: 0, totalCount: 0, paid: 0, paidCount: 0, clearedCount: 0, cleared: 0, pending: 0, approved: 0, notApproved: 0, blank: 0 })

    payroll.sort((a, b) => (a.user?.name || a.employeeName || '\uffff') > (b.user?.name || b.employeeName || '\uffff') ? 1 : -1);

    return  <div className='flex flex-col h-full'>
        <PageHeader title={activeDay.format('MMMM, YYYY')}
            className="payroll-header"
            subTitle={
                <Space>
                    <TimesheetTabsControls onGetPrevious={getPreviousMonth} onGetNext={getNextMonth} />
                    <Button icon={<CalculatorOutlined />} type="primary" className="button-figma-desing h-auto py-1 w-auto" onClick={regeneratePayroll} size="small">Regenerate</Button>
                </Space>} />

        <div className="ant-space-vertical">
            <Table dataSource={payroll.filter(p => p.billAmount > 0 || p.user === null)} rowKey="id" loading={isLoading} pagination={false}
                title={() =>
                    <Space className=" flex-wrap">
                        <Statistic title="Approved" value={totals.approved} style={{ width: "150px" }} />
                        <Statistic title="Pending" value={totals.pending} style={{ width: "150px" }} />
                        <Statistic title="Not approved" value={totals.notApproved} style={{ width: "150px" }} />
                        <Statistic title="Blank" value={totals.blank} style={{ width: "150px" }} />
                        <Statistic title={`Total Paid (${totals.paidCount})`} value={totals.paid} prefix="$" precision={2} style={{ width: "150px" }} />
                        <Statistic title={`Total Cleared (${totals.clearedCount})`} value={totals.cleared} prefix="$" precision={2} style={{ width: "150px" }} />
                        <Statistic title={`Total Amount (${totals.totalCount})`} value={totals.total} prefix="$" precision={2} style={{ width: "150px" }} />
                    </Space>
                }>
                <Table.Column title="Employee" key="user" width={200} render={(_, row: TimesheetMonth) => row.user?.name || <Typography.Text editable={{ triggerType: ["icon", "text"], onChange: value => onUpdate('employeeName', value, row.id) }}>{row.employeeName}</Typography.Text>} />
                <Table.Column title="Bill Amount" dataIndex="billAmount" width={130} render={(billAmount, row: TimesheetMonth) => <Typography.Text editable={row.user === null && { triggerType: ["icon", "text"], onChange: value => onUpdate('billAmount', value.replaceAll('$', '').replaceAll(',', ''), row.id) }}>{formatCurrency(billAmount)}</Typography.Text>} />
                <Table.Column title="Timesheet" dataIndex={["user", "id"]} width={150} render={(id) => id ? <Button type="link" onClick={() => openTimesheet(id)}>Show</Button> : <Typography.Text type="secondary">Manual entry</Typography.Text>} />
                <Table.Column title="Approval" dataIndex="approval" width={180} render={(approval, row: TimesheetMonth) =>
                    <Select style={{ width: '100%' }} defaultValue={approval} onChange={value => onUpdate('approval', value, row.id)}>
                        <Select.Option value="not-approved">Not Approved</Select.Option>
                        <Select.Option value="approved">Approved</Select.Option>
                        <Select.Option value="pending">Pending</Select.Option>
                    </Select>} />
                <Table.Column title="Paid" dataIndex="isPaid" width={80} render={(isPaid, row: any) => <Switch defaultChecked={isPaid} onChange={value => onUpdate('isPaid', value, row.id)} />} />
                <Table.Column title="Cleared" dataIndex="isCleared" width={80} render={(isCleared, row: any) => <Switch defaultChecked={isCleared} onChange={value => onUpdate('isCleared', value, row.id)} />} />
                <Table.Column title="Comment" dataIndex="comment" render={(comment, row: TimesheetMonth) => <Typography.Paragraph editable={{ triggerType: ["icon", "text"], onChange: value => onUpdate('comment', value, row.id) }}>{comment}</Typography.Paragraph>} />
                <Table.Column title="Invoice Link" dataIndex="invoiceLink" render={(invoiceLink, row: any) =>
                    <Space align="baseline">
                        <Typography.Paragraph editable={{ triggerType: ["icon", "text"], onChange: value => onUpdate('invoiceLink', value, row.id) }}>{invoiceLink}</Typography.Paragraph>
                        {invoiceLink && <a href={invoiceLink} target="_blank" rel="noreferrer">Open in Xero</a>}
                    </Space>} />
                <Table.Column title="" key="actions" width={20} render={(_, row: TimesheetMonth) => row.user === null && <Popconfirm title="Are you sure to delete this payroll entry?" onConfirm={() => remove(row.id)} okText="Yes" cancelText="No">
                    <Button type="text" icon={<MinusCircleOutlined />} />
                </Popconfirm>} />
            </Table>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add payroll record
            </Button>
        </div>
        <Modal title={`Timesheet report for ${report ? report[0]?.contactName : ''}`} open={currentTimesheet !== null} width={1200} destroyOnClose={true} footer={null} onCancel={() => setCurrentTimesheet(null)} >
            <Table bordered size="small" dataSource={report} summary={Summary} pagination={false}>
                <Table.Column
                    dataIndex="description"
                    title="Description"
                    render={(_, row: TimesheetReportItem) => `${row.projectCode} - ${row.description}`}
                />
                <Table.Column dataIndex="category" title="Category" />
                <Table.Column dataIndex="rate" title="Rate" align="right" render={formatCurrency} />
                <Table.Column dataIndex="hours" title="Hours" align="right" render={value => value.toFixed(2)} />
                <Table.Column dataIndex="amount" title="Amount" align="right" render={formatCurrency} />
            </Table>
        </Modal>
    </div>
};

const Summary = (data: readonly any[]) => {
    let totalAmount = 0;
    let totalHours = 0;

    data.forEach(({ amount, hours }) => {
        totalAmount += +amount;
        totalHours += +hours;
    });

    return (
        <Table.Summary>
            <Table.Summary.Row>
                <Table.Summary.Cell index={0} align="right" colSpan={3}>
                    {' '}
                    Total
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1} align="right">
                    <Typography.Text>{totalHours.toFixed(2)}</Typography.Text>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={2} align="right">
                    <Typography.Text>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount || 0)}</Typography.Text>
                </Table.Summary.Cell>
            </Table.Summary.Row>
        </Table.Summary>
    );
};

export default Payroll;