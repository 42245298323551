import { useState } from 'react';
import { Button, Form, Input, Divider, Popconfirm, Row, Col, Card } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ExternalLink } from '../../entities';
import Modal from 'antd/lib/modal/Modal';
import { useForm } from 'antd/lib/form/Form';
import { EditOutlined } from '@ant-design/icons';

const getLinksLength = (links: ExternalLink[]): number => {
  return links.filter(l => l.link).length;
};

type Props = {
  links: ExternalLink[];
  onSubmit: (links: ExternalLink[]) => void;
};

const Links = ({ links, onSubmit }: Props) => {
  const [form] = useForm();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onFinish = (values: { links: ExternalLink[] }) => {
    onSubmit(values.links);
    setModalOpen(false);
  };

  return (
    <>
      <Card
        title="Links"
        size="small"
        extra={
          <Button icon={<EditOutlined />} size="small" onClick={() => setModalOpen(true)}>
            Edit
          </Button>
        }
      >
        {!getLinksLength(links) ? (
          <p>No links found</p>
        ) : (
          <ul>
            {links.map(link =>
              link.link ? (
                <li key={link.label}>
                  <a href={link.link} target="_blank" rel="noreferrer">
                    {link.label}
                  </a>
                </li>
              ) : null,
            )}
          </ul>
        )}
      </Card>
      <Modal title="Edit links" open={modalOpen} onOk={() => form.submit()} onCancel={() => setModalOpen(false)} width={1200}>
        <Form form={form} onFinish={onFinish} autoComplete="off" initialValues={{ links }}>
          <Form.List name="links">
            {(fields: any, { add, remove }) => (
              <>
                <Row>
                  <Col span="7">Label</Col>
                  <Col span="1"></Col>
                  <Col span="16">Link</Col>
                </Row>
                <Divider />
                {fields.map((field: any) => (
                  <Row key={field.key}>
                    <Col span="7">
                      <Form.Item {...field} name={[field.name, 'label']} rules={[{ required: true, message: 'Missing label' }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span="1"></Col>
                    <Col span="15">
                      <Form.Item {...field} name={[field.name, 'link']}>
                        <Input style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span="1">
                      <Popconfirm title="Are you sure to delete this link?" onConfirm={() => remove(field.name)} okText="Yes" cancelText="No">
                        <Button type="text" icon={<MinusCircleOutlined />} />
                      </Popconfirm>
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col span="23">
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add link
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default Links;
