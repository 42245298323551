import { Spin } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'antd';
import ProjectTickets from '../../clientComponents/ProjectTickets';
import SubmitTicketForm from '../../clientComponents/SubmitTicketForm';
import { useClient, useSession } from '../../dal';
import ClientLayout from '../../layouts/client/ClientLayout';

const ClientHome = () => {
  const { session } = useSession();
  const { client } = useClient(session?.client!.id);

  const navigate = useNavigate();

  useEffect(() => {
    if (client?.projects[0]) {
      navigate(`/projects/${client.projects[0].id}`);
    }
  }, [client, navigate]);

  if (!client) return <Spin />;

  return (
    <ClientLayout>
      <h1>Projects</h1>
      <ul>
        {client.projects.map(p => (
          <li key={p.id}>{p.name}</li>
        ))}
      </ul>
      <Divider />
      <SubmitTicketForm />
      <Divider />
      <h1>Tickets</h1>
      {client.projects.map(p => (
        <ProjectTickets key={p.id} project={p} />
      ))}
    </ClientLayout>
  );
};

export default ClientHome;
