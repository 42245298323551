import { InputNumber, InputNumberProps } from "antd";

const MoneyInput = (props: InputNumberProps) => (
    <InputNumber
        controls={false}
        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => (value || '').replace(/\$\s?|(,*)/g, '')}
        style={{ width: 120 }}
        {...props} />
)

export default MoneyInput;
