import { Avatar, Select, SelectProps } from 'antd';
import { useUsers } from '../../dal';

const UsersMultiSelector = (props: SelectProps) => {
  const { users = [] } = useUsers();

  return (
    <Select
      {...props}
      mode="multiple"
      showSearch
      placeholder="Select users"
      filterOption={(input, option) => {
        return `${option?.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {users.map(u => (
        <Select.Option key={u.id} value={u.id}>
          <Avatar src={u.picture} style={{ marginRight: '6px', width: '20px', height: '20px' }} size="small" />
          {u.name || u.email}
        </Select.Option>
      ))}
    </Select>
  );
};

export default UsersMultiSelector;
