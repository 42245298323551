import { Spin, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import ProjectDashboard from '../../clientComponents/ProjectDashboard/ProjectDashboard';
import { useProject } from '../../dal';
import ClientLayout from '../../layouts/client/ClientLayout';

import './index.css';

type UrlParams = {
  id: string;
};

const ClientProject = () => {
  const { id } = useParams<UrlParams>();
  const { project } = useProject(id as string);
  if (!project) return <Spin />;

  return (
    <ClientLayout>
      <h1 className="project-name">{project.name}</h1>
      <Tabs defaultActiveKey="1" className="project-tabs">
        <Tabs.TabPane tab="Dashboard" key="1">
          <ProjectDashboard project={project} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Messages" key="2">
          Messages
        </Tabs.TabPane>
        <Tabs.TabPane tab="Invoices" key="3">
          Invoices
        </Tabs.TabPane>
      </Tabs>
    </ClientLayout>
  );
};

export default ClientProject;
