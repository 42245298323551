import { Card, Progress } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useClient, useSession } from '../../dal';
import './ClientProjects.css';

type UrlParams = {
  id: string;
};

const ClientProjects = () => {
  const { id } = useParams<UrlParams>();
  const { session } = useSession();
  const { client } = useClient(session?.client!.id);

  const navigate = useNavigate();

  const handleChangeProject = (id: string) => {
    navigate(`/projects/${id}`);
  };

  if (!client) return <></>;

  return (
    <>
      {client.projects.map(p => (
        <Card className={`client-project-card ${p.id === id && 'client-project-card--active'}`} key={p.id} onClick={() => handleChangeProject(p.id)}>
          <h2>{p.name}</h2>
          <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
            <div>
              <Progress width={42} type="circle" percent={75} />
            </div>
            <div>
              <span className="client-project-stage">Stage</span>
              <br />
              <span className="client-project-phase">{p.phase}</span>
            </div>
          </div>
        </Card>
      ))}
    </>
  );
};

export default ClientProjects;
