import { Button, DatePicker, Form, Input, message, Select, Spin } from 'antd';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { useClient, useSession } from '../dal';

const SubmitTicketForm = () => {
  const { session } = useSession();
  const clientId = session?.client!.id;
  const { client } = useClient(clientId);

  const onFinish = async (values: { name: string; description: string; endDate: Moment; projectId: string }) => {
    await axios.post(`/tickets`, { ...values, endDate: moment(values.endDate).toDate() });
    message.success('Ticket submitted');
  };

  if (!session || !client) {
    return <Spin />;
  }

  return (
    <>
      <h1>Ticket submit form</h1>
      <Form onFinish={onFinish} validateTrigger="onSubmit">
        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input />
        </Form.Item>
        <Form.Item label="Desired end date" name="endDate">
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label="Project" name="projectId" rules={[{ required: true, message: 'Project is required' }]}>
          <Select>
            {client.projects.map(p => (
              <Select.Option key={p.id} value={p.id}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form>
    </>
  );
};

export default SubmitTicketForm;
