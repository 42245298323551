import { useProjectTickets } from '../dal/useProjectTickets';
import { Project } from '../entities';

type Props = {
  project: Project;
};

const ProjectTickets = ({ project }: Props) => {
  const { tickets } = useProjectTickets(project.id);

  return (
    <div style={{ marginBottom: '20px' }}>
      Project: {project.name}
      <br />
      {!!tickets && tickets.length > 0 ? (
        <>
          {tickets.map(t => (
            <p key={t.id}>
              <>
                {t.name} - {t.description} - {t.endDate} - {t.status} - {t.hours}
              </>
            </p>
          ))}
        </>
      ) : (
        <>No tickets</>
      )}
    </div>
  );
};

export default ProjectTickets;
