import useSWR from 'swr';
import { FetchResult, Ticket } from '../entities';

type Result = FetchResult & { tickets: Ticket[] };

export function useProjectTickets(id?: string | null): Result {
  const { data, error, mutate } = useSWR(id ? `/tickets/${id}/project` : null);

  return {
    tickets: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
