import { FC } from 'react';
import { Project } from '../../entities';
import ProjectLinks from '../ProjectLinks/ProjectLinks';
import ProjectTasks from '../ProjectTasks/ProjectTasks';

import './ProjectDashboard.css';

const ProjectDashboard: FC<{ project: Project }> = ({ project }) => {
  return (
    <>
      <ProjectLinks links={project.links} />
      <ProjectTasks projectId={project.id} />
    </>
  );
};

export default ProjectDashboard;
