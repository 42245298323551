import { Col, Spin } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { useSession } from '../../dal';
import Layout from '../../layouts/chat/Chat';
import Messages from '../../components/chat/Messages';
import ReplyForm from '../../components/chat/Editor';

import './index.scss';

import { parse } from 'query-string';
import { URL_PARAMS } from '../../constants';
import { useLocation } from 'react-router-dom';
import { axiosChatInstance } from '../../chat_main';

const controlFunctions: any = {};

const ChannelChat = ({ data, upChatData, idToCheck }) => {
  const [, updateState] = useState({});
  controlFunctions.forceUpdate = useCallback(() => updateState({}), []);
  const location = useLocation();
  const searchParams = parse(data) as { [key: string]: string | null };

  const path = data.substring(1);

  const isAllowToCommunicate = searchParams['allow-comment'];
  const id = searchParams[URL_PARAMS.VIEW] + '';
  const chatId = (searchParams['chat-id'] || 'non-chat') + '';
  axiosChatInstance.defaults.headers.common['ChatId'] = chatId;
  if (searchParams['token']) axiosChatInstance.defaults.headers.common['Token'] = searchParams['token'];
  else {
    axiosChatInstance.defaults.headers.common['Name'] = searchParams['name'];
    axiosChatInstance.defaults.headers.common['Email'] = searchParams['email'];
  }

  const { session, isError, isLoading } = useSession(path);

  const { chatChannels, handleChannel, messages, replyTo, retrieveMessages, setReplyTo, users, loadUsers, chatWhereUserMember } = upChatData;

  const chatChannel = chatChannels?.[chatChannels.findIndex(c => c.id === id)];

  useEffect(() => {
    handleChannel(id, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isError && !isLoading) {
    return (
      <Layout>
        <Col flex="auto" className="h-full justify-center items-center">
          <span className="flex h-full justify-center items-center cursor-pointer !m-0 font-[Proxima Nova] font-[700] text-[#585858] text-[21px] !pl-4 text-ellipsis overflow-hidden whitespace-nowrap">
            Sorry, chat server is unavailable.
          </span>
        </Col>
      </Layout>
    );
  }

  if (!chatChannels || !session || !id)
    return (
      <Layout>
        <Col flex="auto" className="h-full justify-center items-center">
          <div className="flex h-full justify-center items-center">
            <Spin />
          </div>
        </Col>
      </Layout>
    );

  return (
    <Layout>
      <>
        <Messages
          user={session}
          chatChannel={chatChannel}
          messages={messages[id]}
          users={users}
          replyTo={replyTo}
          setReplyTo={setReplyTo}
          loadMessages={(part: number) => {
            retrieveMessages(id, part, true);
          }}
          controlFunctions={controlFunctions}
          style={{ height: `calc(100vh - ${isAllowToCommunicate !== undefined ? '305px' : '0px'})` }}
          isAllowToCommunicate={isAllowToCommunicate}
          chatWhereUserMember={chatWhereUserMember}
        />
        {location && isAllowToCommunicate !== undefined && (
          <ReplyForm
            idToCheck={idToCheck}
            isGlobal={true}
            messages={messages[id]}
            controlFunctions={controlFunctions}
            users={users}
            loadUsers={loadUsers}
            chatId={chatId}
            session={session}
            channel={id}
            channelName={searchParams['channel-name'] || id}
            replyTo={replyTo}
            setReplyTo={setReplyTo}
          />
        )}
      </>
    </Layout>
  );
};

export default ChannelChat;
