import { useState } from 'react';
import moment, { Moment } from 'moment';
import { Col, Divider, Row, Checkbox, DatePicker, Button, Space, Typography } from 'antd';
import { Timesheet } from '../../../entities';
import ReportStats from './ReportStats';
import ReportUserStats from './ReportUsersStats';
import { useMembersRoles } from '../../../dal';

export function filteredTimesheets(timesheets: Timesheet, startDate: Moment | null, endDate: Moment | null): Timesheet {
  if (startDate && endDate) {
    return timesheets.filter(
      t =>
        new Date(t.entryDate) >= new Date(moment(startDate).format('YYYY-MM-DD')) &&
        new Date(t.entryDate) <= new Date(moment(endDate).format('YYYY-MM-DD')),
    );
  }

  if (startDate) {
    return timesheets.filter(t => new Date(t.entryDate) >= new Date(moment(startDate).format('YYYY-MM-DD')));
  }

  if (endDate) {
    return timesheets.filter(t => new Date(t.entryDate) <= new Date(moment(endDate).format('YYYY-MM-DD')));
  }

  return timesheets;
}



type Props = {
  timesheets: Timesheet;
};

const Report = ({ timesheets }: Props) => {

  const timesheetCategories = Array.from(new Set(timesheets.map(item => item.category)));
  const defaultCheckedList = timesheetCategories.map(c => c);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const { roles } = useMembersRoles();
  const data = filteredTimesheets(timesheets, startDate, endDate);

  const onFilterChange = (list: any) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < defaultCheckedList.length);
    setCheckAll(list.length === defaultCheckedList.length);
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? defaultCheckedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={12}>
          <Divider orientation="left">Summary</Divider>
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Space>
            <Button onClick={resetDates}>All time</Button>
            <DatePicker placeholder="Start date" value={startDate} onChange={date => setStartDate(date)} />
            <DatePicker placeholder="End date" value={endDate} onChange={date => setEndDate(date)} />
          </Space>
        </Col>
      </Row>
      <Row gutter={[0, 16]}>
        <ReportStats timesheets={data} categories={timesheetCategories} roles={roles} />
      </Row>
      <Divider orientation="left">Hours by members</Divider>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        Check all
      </Checkbox>
      <br />
      <br />
      <Checkbox.Group style={{ width: '100%' }} onChange={onFilterChange} value={checkedList}>
        <Row>
          {timesheetCategories.map(c => (
            <Col span={8} key={c}>
              <Checkbox value={c}>{c} {roles.indexOf(c) === -1 && <Typography.Text type="danger">legacy</Typography.Text>}</Checkbox>
            </Col>
          ))}
        </Row>
        <br />
        <Row gutter={[0, 16]}>
          <ReportUserStats timesheets={data} checkedList={checkedList} />
        </Row>
      </Checkbox.Group>
    </>
  );
};

export default Report;
